import { useRouter } from "next/router";
import { useEffect } from "react";

import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerFooter,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from "@chakra-ui/react";

interface DrawerProps {
  isOpen: boolean;
  onClose?: any;
  header?: string;
  footer?: string;
  placement?: string;
  autoFocus?: boolean;
  returnFocusOnClose?: boolean;
  onOverlayClick?: any;
  rest?: any;
  children?: any;
}

const DrawerWrapper = ({
  isOpen,
  onClose,
  header,
  footer,
  placement,
  autoFocus,
  returnFocusOnClose,
  onOverlayClick,
  children,
  ...rest
}: DrawerProps) => {
  const router = useRouter();
  const { asPath } = router;

  useEffect(() => {
    onClose();
  }, [asPath, onClose]);

  return (
    <Drawer isOpen={isOpen} onClose={onClose} {...rest}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>{header}</DrawerHeader>

        {children && <DrawerBody px={0}>{children}</DrawerBody>}

        {footer && <DrawerFooter>{footer}</DrawerFooter>}
      </DrawerContent>
    </Drawer>
  );
};

export default DrawerWrapper;
