import { createIcon } from '@chakra-ui/react';

export const SettingsIcon = createIcon({
  displayName: 'SettingsIcon',
  viewBox: '0 0 100 100',
  // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
  path: (
    <g>
      <path
        fill="currentColor"
        d="M90.7,58.6l-7.2-5.3c0.3-2.1,0.3-4.3,0-6.4l7.8-5.5c1.9-1.3,2.5-4.1,1.2-6.1l-8.8-14.7
		c-0.6-1.1-1.5-1.8-2.6-2.1c-1.1-0.3-2.2-0.2-3.1,0.3c-0.1,0-0.2,0.1-0.3,0.2l-8.4,3.8c-1.8-1.2-3.6-2.2-5.5-3.2L63,10.2l0-0.3
		c-0.3-2.1-2.2-3.7-4.4-3.8h-17c-2.3,0-4.3,1.8-4.5,4.2l-0.9,9.4c-1.8,0.8-3.7,1.9-5.5,3.2l-8.5-3.9l-0.2,0c-2.1-0.8-4.4,0-5.6,1.9
		L7.9,35.2c-1.2,2-0.7,4.7,1.2,6.1l7.7,5.4c-0.3,2.1-0.3,4.3,0,6.3l-8.1,5.6c-1.9,1.5-2.4,4.1-1.2,6.1L16,79.4c0.5,1,1.4,1.7,2.5,2
		c1.1,0.3,2.4,0.2,3.4-0.3l8.5-3.9c1.7,1.1,3.5,2.3,5.5,3.2l0.9,9.4l0,0.3c0.3,2.1,2.2,3.7,4.4,3.8h17.1c2.3,0,4.3-1.8,4.5-4.2
		l0.9-9.4c2-0.9,3.8-2,5.5-3.2l8.5,4l0.2,0c2.1,0.8,4.5,0,5.6-1.9l8.5-14.6C93.1,62.7,92.6,60.1,90.7,58.6z M20.9,56.4
		c0.8-0.5,1.3-1.4,1.1-2.5c-0.2-1.2-0.3-2.5-0.4-3.7l0-0.2c0-1.3,0.2-2.5,0.4-3.8l0-0.3c0.1-0.9-0.3-1.9-1-2.4l-8.7-6.1l8.1-13.9
		l9.5,4.4c0.9,0.4,1.9,0.2,2.6-0.4c2.1-1.6,4.3-2.9,7-4c0.8-0.4,1.4-1.1,1.5-2.1l1-10.4h16.2l1,10.5c0.1,1,0.7,1.7,1.5,2.1
		c2.4,1.1,4.8,2.4,7,4.1c0.8,0.5,1.6,0.6,2.5,0.2l9.5-4.4l8.3,14l-8.7,6.1c-0.7,0.5-1.1,1.5-1,2.4c0.2,1.4,0.3,2.6,0.4,4
		c0,1.3-0.2,2.5-0.4,3.8l0,0.3c-0.1,0.9,0.3,1.9,1,2.4l8.2,6.1l-8.1,13.9l-9.5-4.4c-0.9-0.4-1.9-0.2-2.6,0.4c-2.1,1.6-4.3,2.9-7,4
		c-0.8,0.4-1.4,1.1-1.5,2.1l-1,10.4H41.7l-1-10.5c-0.1-0.9-0.6-1.6-1.4-2c-2.4-1.1-4.8-2.4-7-4.1c-0.8-0.5-1.6-0.6-2.5-0.2l-9.6,4.3
		l-8.1-13.9L20.9,56.4z"
      />
      <path
        fill="currentColor"
        d="M49.6,65.7H50c8.7,0,15.7-7,15.7-15.7s-7-15.7-15.7-15.7s-15.7,7-15.7,15.7C34.2,58.6,41.1,65.6,49.6,65.7z
		 M49.7,39.3H50c5.9,0,10.7,4.8,10.7,10.7c0,5.9-4.8,10.7-10.7,10.7c-5.9,0-10.7-4.8-10.7-10.7C39.2,44.2,43.9,39.4,49.7,39.3z"
      />
    </g>
  ),
});
