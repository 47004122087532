import { Ability } from "@casl/ability";

import { IUser } from "types";

export const ability = new Ability();

export const parseUserRole = (user: IUser) => {
  return user.role.name;
};

export const parseUserPermissions = (user: IUser) => {
  if (user.role.permissions) {
    let r: Array<Array<string>> = [];

    user.role.permissions.forEach((e) => {
      let i = e.lastIndexOf("_");

      if (i != -1) {
        let p = e.substring(0, i);
        let a = e.substring(i + 1, e.length);

        r.push([a, p]);
      }
    });

    return r;
  }
};
