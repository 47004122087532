import Drawer from "./Drawer";

export const MediaDrawer = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: any;
}) => (
  <Drawer
    header="Media"
    placement="right"
    isOpen={isOpen}
    onClose={onClose}
    autoFocus={false}
    returnFocusOnClose={false}
    onOverlayClick={onClose}
  />
);
