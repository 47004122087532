import { ApolloProvider } from "@apollo/client";
import { ChakraProvider, CSSReset, Box } from "@chakra-ui/react";

import Layout from "components/Layout";
import SEO from "components/SEO";

//- Global CSS
import "react-big-calendar/lib/css/react-big-calendar.css";
import { theme } from "ui/theme";
import "public/index.css";
import NavRow from "components/NavRow";
``;
//- Toasts
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//- Context
import { AuthGuard, LoadingWrapper, NotificationsWrapper } from "ui";

//- GQL
import { client } from "gql";

export default function App({
  Component,
  pageProps: { session, ...pageProps },
}) {
  return (
    <ApolloProvider client={client}>
      <ChakraProvider theme={theme}>
        <CSSReset />
        <AuthGuard>
          {/* <NotificationsWrapper> */}
          <Layout>
            <SEO />
            <Component {...pageProps} />
          </Layout>
          <Box display={{ base: "block", md: "none" }}>
            <NavRow />
          </Box>
          <ToastContainer
            position="bottom-left"
            autoClose={3000}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover={false}
          />
          {/* </NotificationsWrapper> */}
        </AuthGuard>
      </ChakraProvider>
    </ApolloProvider>
  );
}
