import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Collapse,
  Checkbox,
  HStack,
} from "@chakra-ui/react";
import { useController } from "react-hook-form";

interface ICustomerCheckbox {
  name: string;
  label: string;
  errors: { [key: string]: { message: string } };
  canedit: boolean;
  control: any;
  rules: any;
  props: any;
}

export const CustomCheckbox = ({
  name,
  label,
  errors,
  canedit,
  control,
  rules,
  ...props
}: ICustomerCheckbox) => {
  const { field } = useController({
    name,
    control,
    rules,
  });
  return (
    <FormControl>
      <HStack>
        <FormLabel htmlFor={name} m={0}>
          {label}
        </FormLabel>
        <Checkbox
          m={0}
          id={name}
          placeholder={label}
          isDisabled={canedit}
          {...field}
          {...props}
        />
      </HStack>
      <Collapse in={!!errors?.[name]?.message}>
        <FormErrorMessage>
          {errors[name] && errors[name].message}
        </FormErrorMessage>
      </Collapse>
    </FormControl>
  );
};
