import { Stack, Text, Box } from "@chakra-ui/react";
import Link from "next/link";
import { useRouter } from "next/router";

import { menuLinks } from "../consts";

const NavRow = ({}) => {
  const { pathname } = useRouter();
  return (
    <Stack
      direction={{ base: "row", md: "column" }}
      justifyContent="space-around"
      w="full"
      spacing={4}
    >
      {menuLinks.map(({ title, link }) => (
        <Link href={link} key={title}>
          <Box
            key={title}
            color={pathname === link ? "brand.100" : "brand.font"}
            _hover={{
              color: "brand.100",
              borderColor: "brand.100",
            }}
            borderColor={pathname === link ? "brand.100" : "none"}
            borderLeftWidth={"3px"}
            pl={4}
            w="full"
            cursor="pointer"
          >
            <Text fontSize="lg"> {title} </Text>
          </Box>
        </Link>
      ))}
    </Stack>
  );
};

export default NavRow;
