export const brand_100 = '#333889';
export const brand_200 = '#84805b';
export const brand_300 = '#a7a37e';
export const brand_400 = '#dcdcdc';
export const brand_500 = '#e7e7e7';
export const brand_600 = '#595973';
export const brand_700 = '#8889a5';

export const colors = {
  brand: {
    font: '#3c3c3c',
    100: brand_100,
    200: brand_200,
    300: brand_300,
    400: brand_400,
    500: brand_500,
    600: brand_600,
    700: brand_700,
  },
};
