export * from "./muscleGroup";
export * from "./roles";
export * from "./validationMessages";
export * from "./validationRegex";

import { IUser } from "types";

export const initialUser: IUser = {
  _id: "",
  role: { name: "", permissions: [""] },
  email: "",
  name: { firstName: "", surname: "" },
  profilePicture: "",
};

export const bookingStatuses = [
  "Requested",
  "Complete",
  "In progress",
  "Confirmed",
  "Declined",
  "Refunded",
];

export const NOT_ACTIVATED = "Not activated";
export const ACTIVATED = "Activated";
export const DEACTIVATED = "Deactivated";
