import Image from "next/image";
import { ReactNode } from "react";
import {
  chakra,
  Container,
  Flex,
  HStack,
  Icon,
  useBreakpointValue,
} from "@chakra-ui/react";

import FooterBottom from "./FooterBottom";
import { PurpleSecondaryLogo } from "ui";

export const Footer = () => {
  return (
    <chakra.footer>
      <FooterTop />
      <FooterBottom />
    </chakra.footer>
  );
};

const FooterTop = ({}) => (
  <Container maxW="container.90" py={5}>
    <Flex
      justifyContent="space-between"
      alignItems={{ base: "flex-start", md: "center" }}
    >
      <FooterSocial />
      <Icon
        display={{ base: "none", lg: "unset" }}
        w={200}
        h={200}
        as={PurpleSecondaryLogo}
      />
    </Flex>
  </Container>
);

interface ISocialLink {
  image: string;
  style?: {
    color?: string;
  };
  link: string;
  alt: string;
  icon?: ReactNode;
}

const socialLinks: Array<ISocialLink> = [
  {
    image:
      "https://mtf-content.fra1.cdn.digitaloceanspaces.com/website-images%2Ffacebook.svg",
    style: { color: "#4267B2" },
    link: "https://facebook.com",
    alt: "Make Time fit Facebook",
  },
  // {
  //   image: 'https://mtf-content.fra1.cdn.digitaloceanspaces.com/website-images%2Ftwitter.svg',
  //   style: { color: '#1DA1F2' },
  //   link: 'https://twitter.com',
  // },
  // {
  //   image: '/social_media_icons/Linkedin.svg',
  //   alt: 'Make Time Fit LinkedIn',
  //   style: {
  //     backgroundColor: '#0e76a8',
  //     color: 'white',
  //     borderRadius: '5px',
  //   },
  //   link: 'https://linkedin.com',
  // },
  {
    image:
      "https://mtf-content.fra1.cdn.digitaloceanspaces.com/website-images%2Finstagram.svg",
    alt: "Make Time Fit Instagram",
    style: {},
    link: "https://www.instagram.com/maketimefit/",
  },
];

const FooterSocial = () => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  return (
    <HStack spacing={5} py={3}>
      {socialLinks.map((social, index) => (
        <a
          href={social.link}
          key={index.toString()}
          rel="noopener noreferrer nofollow"
          target="_blank"
        >
          <Image
            src={social.image}
            width={isMobile ? 25 : 50}
            height={isMobile ? 25 : 50}
            style={{
              ...social.style,
            }}
            alt={social.alt}
          />
        </a>
      ))}
    </HStack>
  );
};
