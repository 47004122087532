import { Stack, Icon } from "@chakra-ui/react";
import Link from "next/link";

import { menuLinks } from "./consts";

const NavRow = ({}) => (
  <Stack
    direction={{ base: "row", md: "column" }}
    py="smaller"
    justifyContent="space-around"
    alignItems="center"
    bg="brand.200"
    w="full"
    position={"fixed"}
    bottom={0}
    left={0}
  >
    {menuLinks.map((link) => (
      <Link href={link.link} key={link.title}>
        {link.icon && (
          <Icon
            color="white"
            as={link.icon}
            w={{ base: 35, md: 75 }}
            h={{ base: 35, md: 75 }}
          />
        )}
      </Link>
    ))}
  </Stack>
);

export default NavRow;
