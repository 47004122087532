import { gql } from "@apollo/client";

export const CREATE_NEW_BOOKING = gql`
  mutation createNewBooking($booking: NewBookingPayload) {
    createNewBooking(booking: $booking) {
      ... on BookingPayload {
        __typename
        _id
        status
        client {
          ... on UserPayload {
            _id
            profile {
              name {
                firstName
                surname
              }
            }
          }
        }
        start
        end
        # location {
        #   name
        #   lineOne
        #   city
        #   postcode
        # }
        service {
          title
          price
        }
        stripe {
          paymentIntentId
          chargeId
          refundId
        }
      }
      ... on GeneralErrorPayload {
        message
      }
    }
  }
`;
