import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  InputGroup,
  InputRightElement,
  useDisclosure,
  useColorModeValue as mode,
} from "@chakra-ui/react";
import { useController, FieldErrors, FieldValues } from "react-hook-form";
import { HiEye, HiEyeOff } from "react-icons/hi";

import { FormInput } from "./FormInput";

interface IPasswordField {
  resetPassword: boolean;
  errors: FieldErrors<FieldValues>;
  handleKeypress?: () => void;
  control: any;
  rules: any;
  props?: any;
}

export const PasswordField = ({
  errors,
  resetPassword,
  handleKeypress,
  control,
  rules,
  ...props
}: IPasswordField) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <FormControl id="password">
      <InputGroup mb={4}>
        <FormInput
          name="password"
          label="Password"
          errors={errors}
          type={isOpen ? "text" : "password"}
          control={control}
          rules={rules}
          {...props}
        />
        {/* <InputRightElement>
          <IconButton
            bg="transparent !important"
            variant="ghost"
            aria-label={isOpen ? "Mask password" : "Reveal password"}
            icon={isOpen ? <HiEyeOff /> : <HiEye />}
            onClick={onToggle}
          />
        </InputRightElement> */}
      </InputGroup>
    </FormControl>
  );
};

PasswordField.displayName = "PasswordField";
