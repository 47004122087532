import { gql } from "@apollo/client";

export const UPDATE_PERSONAL_DETAILS = gql`
  mutation updatePersonalDetails($updateProfileInput: UpdateProfileInput!) {
    updatePersonalDetails(updateProfileInput: $updateProfileInput) {
      status
      message
    }
  }
`;

export const UPDATE_CONTACT_DETAILS = gql`
  mutation updateContactDetails($updateProfileInput: UpdateProfileInput!) {
    updateContactDetails(updateProfileInput: $updateProfileInput) {
      status
      message
    }
  }
`;

export const TOGGLE_USER_BLOCKED = gql`
  mutation toggleUserBlocked($updateUserInput: UpdateUserInput!) {
    toggleUserBlocked(updateUserInput: $updateUserInput) {
      status
      message
    }
  }
`;

export const TOGGLE_USER_APPROVED = gql`
  mutation toggleUserApproved($updateUserInput: UpdateUserInput!) {
    toggleUserApproved(updateUserInput: $updateUserInput) {
      status
      message
    }
  }
`;

export const ADMIN_CREATE_USER = gql`
  mutation adminInviteUser($createUserInput: AdminCreateNewUserPayload!) {
    adminInviteUser(createUserInput: $createUserInput) {
      status
      message
    }
  }
`;
