import { ReactNode, useEffect, useMemo } from "react";
import { createContext, useContext } from "react";
import { useMutation } from "@apollo/client";
import useSWR from "swr";
import { useRouter } from "next/navigation";
import { toast } from "react-toastify";

//- Casl
import { Ability, AbilityBuilder } from "@casl/ability";
import {
  parseUserPermissions,
  parseUserRole,
  ability,
  AbilityContext,
} from "ui";

// Gql
import { fetcher, GET_SESSION_USER, LOGIN, LOGOUT } from "gql";

// Types & consts
import { initialUser } from "consts";
import { IUser } from "types";

const parseUser = (user: any) => {
  if (user?._id) {
    const { _id, role, email, profile } = user;
    const { profilePicture, name } = profile || {};
    const { name: roleName, permissions } = role || {};
    const permissionsMapped = permissions.map(
      ({ name }: { name: string }) => name
    );
    return {
      _id,
      role: {
        name: roleName,
        permissions: permissionsMapped ?? [],
      },
      email,
      name,
      profilePicture,
    };
  } else return initialUser;
};

interface IAuthContext {
  auth: IUser;
  handleLogout: () => void;
  handleLogin: (values: { email: string; password: string }) => Promise<{
    user: IUser;
    message: string;
    status: number;
  }>;
  authLoading: boolean;
  refetchUser: () => void;
}

//* Context
export const AuthContext = createContext<IAuthContext>({
  auth: initialUser,
  handleLogout: () => {},
  refetchUser: () => {},
  handleLogin: async ({ email, password }) => ({
    user: initialUser,
    message: "",
    status: 200,
  }),
  authLoading: false,
});

export function useAuth() {
  return useContext(AuthContext);
}

const updateUserAbility = (user: IUser) => {
  let role = parseUserRole(user);

  let permissions = parseUserPermissions(user);

  if (permissions) {
    const { can, rules } = new AbilityBuilder(Ability);

    if (role === "GOD") {
      can("manage", "all");
    } else {
      permissions.forEach((p) => {
        can(p[1], p[0]);
      });
    }

    ability.update(rules);
  }
};

export const AuthGuard = ({ children }: { children: ReactNode }) => {
  const [logout] = useMutation(LOGOUT);
  const [loginUser] = useMutation(LOGIN);
  const { push } = useRouter();
  const { data, isLoading, mutate } = useSWR(
    { query: GET_SESSION_USER },
    fetcher,
    { refreshInterval: 0 }
  );

  const auth = useMemo(
    () => parseUser(data?.data.getUserBySession),
    [data?.data]
  );

  const handleLogin = async (values: { email: string; password: string }) => {
    const {
      data: { login },
    } = await loginUser({ variables: { ...values } });
    return login;
  };

  const handleLogout = async () => {
    await logout({});
    toast.success("Logged out!");
    push(process.env.NEXT_PUBLIC_WEBSITE_URL ?? "https://maketimefit.co.uk");
  };

  useEffect(() => {
    if (
      !!parseUser(auth).role?.name &&
      !!parseUser(auth).role?.permissions?.length
    )
      updateUserAbility(auth);
  }, [auth]);

  return (
    <AbilityContext.Provider value={ability}>
      <AuthContext.Provider
        value={{
          auth,
          handleLogout,
          authLoading: isLoading,
          handleLogin,
          refetchUser: mutate,
        }}
      >
        {children}
      </AuthContext.Provider>
    </AbilityContext.Provider>
  );
};
