import { gql } from "@apollo/client";

export const CREATE_SERVICE = gql`
  mutation createService($service: ServiceInputPayload) {
    createService(service: $service) {
      status
      message
    }
  }
`;

export const UPDATE_SERVICE = gql`
  mutation updateService($service: ServiceInputPayload) {
    updateService(service: $service) {
      ... on GeneralResponsePayload {
        status
        message
      }
      ... on GeneralErrorPayload {
        status
        message
      }
    }
  }
`;

export const DELETE_SERVICE = gql`
  mutation deleteService($_id: ID!, $userId: ID!) {
    deleteService(_id: $_id, userId: $userId) {
      ... on GeneralResponsePayload {
        status
        message
      }
      ... on GeneralErrorPayload {
        status
        message
      }
    }
  }
`;
