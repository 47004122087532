import { useEffect, useRef, memo } from "react";
import {
  FormControl,
  FormHelperText,
  FormLabel,
  Collapse,
  FormErrorMessage,
} from "@chakra-ui/react";
import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
} from "@choc-ui/chakra-autocomplete";

interface IAutocomplete {
  helperText: string;
  label: string;
  options: Array<{ label: string; value: string }>;
  name: string;
  errors: { [key: string]: { message: string } };
  handleChange: () => void;
  handleInputChange: () => void;
  loading: boolean;
  multiple: boolean;
  openOnFocus: boolean;
  rest: any;
}

export const AutocompleteWrapper = ({
  helperText = "",
  label = "",
  options = [],
  name = "",
  errors = {},
  handleChange = () => {},
  handleInputChange = () => {},
  loading = false,
  multiple = false,
  openOnFocus = true,
  ...rest
}: IAutocomplete) => (
  <FormControl>
    <FormLabel>{label}</FormLabel>
    <AutoComplete
      emphasize
      openOnFocus={openOnFocus}
      onChange={handleChange}
      style={{ position: "relative" }}
      multiple={multiple}
    >
      <AutoCompleteInput
        id={name}
        name={name}
        placeholder={label}
        onChange={handleInputChange}
      />
      <AutoCompleteList>
        {options?.map((_option, index) => (
          <AutoCompleteItem
            key={`${name}-${index}`}
            value={_option?.value}
            label={_option?.label}
          />
        ))}
      </AutoCompleteList>
    </AutoComplete>
    {helperText && <FormHelperText>{helperText}</FormHelperText>}
    <Collapse in={!!errors?.[name]?.message}>
      <FormErrorMessage>
        {errors?.[name] && errors?.[name].message}
      </FormErrorMessage>
    </Collapse>
  </FormControl>
);
