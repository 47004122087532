import { createIcon } from "@chakra-ui/react";

import { brand_100, brand_200 } from "../theme/colors";

export const PrimaryLogo = createIcon({
  displayName: "PrimaryLogo",
  viewBox: "0 0 1136.11 219.28",
  // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
  path: (
    <>
      <path
        fill={brand_100}
        stroke={brand_100}
        strokeMiterlimit="10"
        strokeWidth="5"
        d="M730.05 216.78a107.14 107.14 0 11107.14-107.14 107.26 107.26 0 01-107.14 107.14zm0-207.28a100.14 100.14 0 10100.14 100.14A100.26 100.26 0 00730.05 9.5z"
      />
      <path
        d="M1045.34 542.17V542a3.34 3.34 0 00-3.33-3.39h-10.33a3.34 3.34 0 00-3.34 3.34v.32a3.35 3.35 0 003.34 3.34H1042a3.35 3.35 0 003.34-3.39zM1221.4 538.6a3.34 3.34 0 00-3.34 3.39v.22a3.36 3.36 0 003.34 3.39h10.06a3.34 3.34 0 003.34-3.34v-.32a3.33 3.33 0 00-3.34-3.34zM1197.48 481l7-7a3.47 3.47 0 000-4.9v-.05a3.47 3.47 0 00-4.9 0l-7 7a3.44 3.44 0 000 4.89v.05a3.46 3.46 0 004.9.01zM1065.53 603l-6.63 6.63a3.47 3.47 0 000 4.9 3.45 3.45 0 004.9 0l6.63-6.62a3.47 3.47 0 000-4.9 3.46 3.46 0 00-4.9-.01zM1131.7 455.81a3.43 3.43 0 003.46-3.41v-10.53a3.41 3.41 0 00-3.41-3.41h-.18a3.41 3.41 0 00-3.41 3.41v10.53a3.43 3.43 0 003.46 3.41zM1131.7 628.53a3.43 3.43 0 00-3.46 3.41v10.39a3.41 3.41 0 003.41 3.41h.18a3.41 3.41 0 003.41-3.41v-10.39a3.43 3.43 0 00-3.46-3.41zM1071.3 475.58l-7-7a3.45 3.45 0 00-4.9 0 3.47 3.47 0 000 4.9l7 7a3.46 3.46 0 004.88 0v-.05a3.46 3.46 0 00.02-4.85zM1193.35 607.52l7.26 7.26a3.47 3.47 0 004.9 0 3.47 3.47 0 000-4.9l-7.27-7.26a3.46 3.46 0 00-4.91 0 3.46 3.46 0 00.02 4.9z"
        fill={brand_100}
        className="cls-2"
        transform="translate(-401.65 -432.6)"
      />
      <path
        d="M1067.16 553.6v-16.19h-5.11v-7.57h5.11v-6.73l9.39-2.1v8.83h9.68v7.57h-9.68v16.05c0 3 1.48 4 3.16 4a8.57 8.57 0 004.48-1.47l2.67 6.94a16.19 16.19 0 01-8.9 2.66c-6.96-.01-10.8-4.08-10.8-11.99zM1089.87 520a5.57 5.57 0 115.53 5.53 5.6 5.6 0 01-5.53-5.53zm.91 9.81h9.39v35h-9.39zM1106.62 529.84h9.18v4.69a12.68 12.68 0 0110.37-5.39c5.54 0 9 2.31 10.79 6.23a13.53 13.53 0 0111.35-6.23c9.89 0 13 6.8 13 15.77v20h-9.39V546.1c0-4.63-1.12-8.06-6.1-8.06-4.62 0-7.15 3.71-7.15 8.34v18.5h-9.39V546.1c0-4.63-1-8.06-6.1-8.06-4.76 0-7.14 4.06-7.14 8.69v18.15h-9.39zM1166.18 547.36a18 18 0 0118.15-18.22c9.53 0 17 8.13 17 18.08a31 31 0 01-.21 3.36h-25.44c1.12 4.84 5.12 7.15 9.74 7.15a17.38 17.38 0 009.46-2.73l3.51 6.73a23.64 23.64 0 01-13 3.85c-10.24 0-19.21-6.66-19.21-18.22zm26.35-3a8.46 8.46 0 00-8.48-7.35 8.78 8.78 0 00-8.48 7.35z"
        strokeMiterlimit="10"
        fill={brand_200}
        stroke={brand_200}
        strokeWidth="2px"
        className="cls-3"
        transform="translate(-401.65 -432.6)"
      />
      <path
        d="M401.65 467.15h30.25l25.5 36c9.5 13.25 29.25 42 29.25 42s20-28.5 29.25-41.75l25.5-36.25h30.25v162h-35v-102.5s-6.25 10.25-14.75 22.5l-35.25 50.25-35.5-50.5c-8.25-11.75-14.5-22.25-14.5-22.25v102.5h-35zM594.15 566.65c0-36 22-65 56.5-65 14.5 0 30 5.75 39 17.75v-15.25h32.75v125h-32.75v-15c-8.75 12-24.5 17.5-39 17.5-34.5 0-56.5-29-56.5-65zm94.75 0c0-19.5-12.5-34-29.75-34-18 0-31 14.5-31 34 0 19.75 13 34 31 34 17.25 0 29.75-14 29.75-34zM745.4 464.65h33.5v91.5l43-52h40.75l-48.75 54.5 57 70.5h-40.25l-37.5-47.75-14.25 17v30.75h-33.5zM869.4 566.65c0-36 28-65 64.75-65 34 0 60.75 29 60.75 64.5a110.88 110.88 0 01-.75 12H903.4c4 17.25 18.25 25.5 34.75 25.5 12 0 24.5-3.5 33.75-9.75l12.5 24a84.21 84.21 0 01-46.5 13.75c-36.5 0-68.5-23.75-68.5-65zm94-10.75c-2.5-15.75-15-26.25-30.25-26.25-14.5 0-27 10.5-30.25 26.25zM1268.51 467.15h121.25v30h-86.25v33H1377v29.75h-73.5v69.25h-35zM1399.26 469.15A19.88 19.88 0 111419 488.9a20 20 0 01-19.74-19.75zm3.25 35H1436v125h-33.5zM1467.51 588.9v-57.75h-18.25v-27h18.25v-24l33.5-7.5v31.5h34.5v27H1501v57.25c0 10.75 5.25 14.25 11.25 14.25 8 0 15-4.5 16-5.25l9.5 24.75a57.61 57.61 0 01-31.75 9.5c-25 0-38.49-14.5-38.49-42.75z"
        fill={brand_100}
        className="cls-2"
        transform="translate(-401.65 -432.6)"
      />
    </>
  ),
});
