import { gql } from '@apollo/client';

export const GET_MEDIA = gql`
  query media($page: Int, $limit: Int, $filter: MediaFilterPayload) {
    getMedia(page: $page, limit: $limit, filter: $filter) {
      ... on MediaPayload {
        docs {
          ... on SingleMediaPayload {
            _id
            title
            url
            type
          }
        }
      }
      ... on GeneralErrorPayload {
        message
        status
      }
    }
  }
`;
