import { gql } from '@apollo/client';

export const DELETE_DOCUMENT = gql`
  mutation deleteDocument($id: String!, $userId: String!) {
    deleteDocument(id: $id, userId: $userId) {
      ... on GeneralResponsePayload {
        status
        message
      }
      ... on GeneralErrorPayload {
        status
        message
      }
    }
  }
`;
