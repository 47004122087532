import { Container, useBreakpointValue, VStack } from "@chakra-ui/react";
import { ReactNode } from "react";

interface IPageContainer {
  children: any;
  py?: number | string;
  spacing?: any;
  alignItems?: string;
  props?: any;
}

export const PageContainer: (props: IPageContainer) => JSX.Element = ({
  children,
  py,
  spacing,
  alignItems,
  ...props
}) => {
  const defaultPaddingY = useBreakpointValue({ base: 50, md: 75 });

  return (
    <Container
      maxW={{ base: "full", md: "container.90" }}
      py={py ? py : defaultPaddingY}
      {...props}
    >
      <VStack spacing={spacing || 50} alignItems={alignItems}>
        {children}
      </VStack>
    </Container>
  );
};
