import { Dropdown } from "ui";

const prefixArray = [
  { label: "Mrs" },
  { label: "Miss" },
  { label: "Ms" },
  { label: "Mr" },
  { label: "Dr" },
  { label: "Mx" },
  { label: "Prefer not to say" },
];

export const PrefixDropdown = (props: any) => (
  <Dropdown options={prefixArray} {...props} />
);
