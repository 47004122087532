import { Dropdown } from ".";

const pronounsArray = [
  { label: "He/him" },
  { label: "She/her" },
  { label: "They/them" },
  { label: "Prefer not to say" },
];

export const PronounsDropdown = (props: any) => (
  <Dropdown options={pronounsArray} {...props} />
);
