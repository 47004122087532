import {
  Input as ChakraInput,
  Textarea,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from "@chakra-ui/react";

export const TextArea = (props: any) => <Textarea resize={"none"} {...props} />;

export const Input = (props: any) => (
  <ChakraInput
    id={props?.name}
    name={props?.name}
    placeholder={props?.label}
    isDisabled={props?.canedit}
    type={props?.type}
    {...props?.field}
    {...props}
  />
);

export const Number = (props: any) => (
  <NumberInput {...props}>
    <NumberInputField />
    <NumberInputStepper>
      <NumberIncrementStepper />
      <NumberDecrementStepper />
    </NumberInputStepper>
  </NumberInput>
);

export const renderInput = (type: string, props: any) => {
  switch (type) {
    case "textarea":
      return <TextArea {...props} />;
    case "text":
    case "email":
      return <Input {...props} />;
    case "password":
      return <Input {...props} type="password" />;
    case "number":
      return <Number {...props} />;
    case "date":
      return <Input {...props} type="date" />;
    case "time":
      return <Input {...props} type="time" />;
    default:
      return;
  }
};
