import { createIcon } from "@chakra-ui/react";

export const ProfileIcon = createIcon({
  displayName: "ProfileIcon",
  viewBox: "0 0 100 100",
  // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
  path: (
    <g>
      <path
        fill="currentColor"
        d="M50,45.1c12,0,21.7-9.7,21.7-21.7S62,1.7,50,1.7c-12,0-21.7,9.7-21.7,21.7S38,45.1,50,45.1z M50,7.2
		c8.9,0,16.2,7.3,16.2,16.2S58.9,39.6,50,39.6s-16.2-7.3-16.2-16.2S41.1,7.2,50,7.2z"
      />
      <path
        fill="currentColor"
        d="M50,48C27.8,48,9.7,66,9.7,88.2v7.3c0,1.5,1.3,2.8,2.9,2.8h74.9c1.5,0,2.8-1.3,2.8-2.8v-7.3
		C90.3,66,72.2,48,50,48z M50,53.5c19.1,0,34.7,15.6,34.7,34.7v4.6H15.3v-4.6C15.3,69.1,30.9,53.5,50,53.5z"
      />
    </g>
  ),
});
