import { useState, createContext, useContext, ReactNode } from "react";
import { useQuery } from "@apollo/client";

import { GET_NOTIFICATIONS } from "gql";
import { INotifications, INotificationContext } from "types";

export const initialState = {
  docs: [],
  total: 0,
  page: 1,
  hasNextPage: false,
  hasPrevPage: false,
  hasNew: false,
};

export const NotificationContext = createContext<INotificationContext>({
  notifications: initialState,
  setNotifications: () => {},
});

export function useNotifications() {
  return useContext(NotificationContext);
}

export const NotificationsWrapper = ({ children }: { children: ReactNode }) => {
  const [notifications, setNotifications] =
    useState<INotifications>(initialState);

  useQuery(GET_NOTIFICATIONS, {
    variables: { page: 1 },
    pollInterval: 1000 * 30,
    onCompleted: (data) => {
      const { getNotifications } = data || {};
      if (getNotifications?.__typename == "NotificationsPayload") {
        setNotifications(getNotifications);
      }
    },
  });

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        setNotifications,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
