import {
  BookingIcon,
  HistoryIcon,
  ProfileIcon,
  SearchIcon,
  SettingsIcon,
} from "./icons/";

export const menuLinks = [
  {
    title: "Profile",
    link: "/",
    icon: ProfileIcon,
  },
  {
    title: "Planner",
    link: "/planner",
    icon: BookingIcon,
  },
  {
    title: "Settings",
    link: "/settings",
    icon: SettingsIcon,
  },
];

export let footerLinks = [
  {
    title: "Home",
    link: `${process.env.NEXT_PUBLIC_WEBSITE_URL}/`,
  },
  {
    title: "About",
    link: `${process.env.NEXT_PUBLIC_WEBSITE_URL}/about`,
  },
  {
    title: "Sign Up",
    link: `${process.env.NEXT_PUBLIC_WEBSITE_URL}/sign-up`,
  },
];

footerLinks.push({
  title: "Contact",
  link: `${process.env.NEXT_PUBLIC_WEBSITE_URL}/contact`,
});
