import dayjs from "dayjs";
import useSWR from "swr";
import { useMemo } from "react";
import { Flex, Text } from "@chakra-ui/react";

import { fetcher, GET_MY_BOOKINGS } from "gql";

export const UpcomingBookings = () => {
  const { start, end } = useMemo(
    () => ({
      start: dayjs().startOf("day"),
      end: dayjs().endOf("day"),
    }),
    []
  );
  const { data, isLoading, error } = useSWR(
    {
      query: GET_MY_BOOKINGS,
      variables: { start, end },
    },
    fetcher
  );

  // TODO: Loading state
  if (isLoading) return null;
  return (
    <Flex flexDirection="column">
      <Text size="xl"> {`Your upcoming bookings`}</Text>
      {data?.data.myBookings?.map((_booking: any) => (
        <div>
          <Text>{`${dayjs(_booking.start, "HH:mm")}`}</Text>
          <Text>{`${dayjs(_booking.end, "HH:mm")}`}</Text>
          <Text>
            Trainer: {_booking.trainer.profile.name.firstName}{" "}
            {_booking.trainer.profile.name.surname}
          </Text>
        </div>
      ))}
    </Flex>
  );
};
