import { createContext, useContext } from "react";

import { IUser } from "types";
import { initialUser } from "consts";

interface IUserContext {
  user: IUser;
}

//- Context
export const UserContext = createContext<IUserContext>({
  user: initialUser,
});
export const useUser = () => useContext(UserContext);
