import { createIcon } from "@chakra-ui/react";

import { brand_200 } from "../theme/colors";

export const PrimaryWhite = createIcon({
  displayName: "PrimaryWhite",
  viewBox: "0 0 1136.11 219.28",
  // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
  path: (
    <>
      <path
        fill={brand_200}
        stroke={brand_200}
        strokeWidth="5px"
        strokeMiterlimit="10"
        className="cls-1"
        d="M1131.7,649.38a107.14,107.14,0,1,1,107.14-107.14A107.26,107.26,0,0,1,1131.7,649.38Zm0-207.28a100.14,100.14,0,1,0,100.14,100.14A100.26,100.26,0,0,0,1131.7,442.1Z"
        transform="translate(-401.65 -432.6)"
      />
      <path
        fill={brand_200}
        className="cls-2"
        d="M1045.34,542.17V542a3.34,3.34,0,0,0-3.33-3.39h-10.33a3.34,3.34,0,0,0-3.34,3.34v.32a3.35,3.35,0,0,0,3.34,3.34H1042a3.35,3.35,0,0,0,3.34-3.39Z"
        transform="translate(-401.65 -432.6)"
      />
      <path
        fill={brand_200}
        className="cls-2"
        d="M1221.4,538.6a3.34,3.34,0,0,0-3.34,3.39v.22a3.36,3.36,0,0,0,3.34,3.39h10.06a3.34,3.34,0,0,0,3.34-3.34v-.32a3.33,3.33,0,0,0-3.34-3.34Z"
        transform="translate(-401.65 -432.6)"
      />
      <path
        fill={brand_200}
        className="cls-2"
        d="M1197.48,481l7-7a3.47,3.47,0,0,0,0-4.9l0-.05a3.47,3.47,0,0,0-4.9,0l-7,7a3.44,3.44,0,0,0,0,4.89l0,.05A3.46,3.46,0,0,0,1197.48,481Z"
        transform="translate(-401.65 -432.6)"
      />
      <path
        fill={brand_200}
        className="cls-2"
        d="M1065.53,603l-6.63,6.63a3.47,3.47,0,0,0,0,4.9l0,0a3.45,3.45,0,0,0,4.9,0l6.63-6.62a3.47,3.47,0,0,0,0-4.9l0,0A3.46,3.46,0,0,0,1065.53,603Z"
        transform="translate(-401.65 -432.6)"
      />
      <path
        fill={brand_200}
        className="cls-2"
        d="M1131.7,455.81h0a3.43,3.43,0,0,0,3.46-3.41V441.87a3.41,3.41,0,0,0-3.41-3.41h-.18a3.41,3.41,0,0,0-3.41,3.41V452.4a3.43,3.43,0,0,0,3.46,3.41Z"
        transform="translate(-401.65 -432.6)"
      />
      <path
        fill={brand_200}
        className="cls-2"
        d="M1131.7,628.53h0a3.43,3.43,0,0,0-3.46,3.41v10.39a3.41,3.41,0,0,0,3.41,3.41h.18a3.41,3.41,0,0,0,3.41-3.41V631.94a3.43,3.43,0,0,0-3.46-3.41Z"
        transform="translate(-401.65 -432.6)"
      />
      <path
        fill={brand_200}
        className="cls-2"
        d="M1071.3,475.58l-7-7a3.45,3.45,0,0,0-4.9,0l0,0a3.47,3.47,0,0,0,0,4.9l7,7a3.46,3.46,0,0,0,4.88,0l0-.05A3.46,3.46,0,0,0,1071.3,475.58Z"
        transform="translate(-401.65 -432.6)"
      />
      <path
        fill={brand_200}
        className="cls-2"
        d="M1193.35,607.52l7.26,7.26a3.47,3.47,0,0,0,4.9,0l0,0a3.47,3.47,0,0,0,0-4.9l-7.27-7.26a3.46,3.46,0,0,0-4.91,0l0,0A3.46,3.46,0,0,0,1193.35,607.52Z"
        transform="translate(-401.65 -432.6)"
      />
      <path
        strokeMiterlimit="10"
        fill="#fff"
        stroke="#fff"
        strokeWidth="2px"
        className="cls-3"
        d="M1067.16,553.6V537.41h-5.11v-7.57h5.11v-6.73l9.39-2.1v8.83h9.68v7.57h-9.68v16.05c0,3,1.48,4,3.16,4a8.57,8.57,0,0,0,4.48-1.47l2.67,6.94a16.19,16.19,0,0,1-8.9,2.66C1071,565.58,1067.16,561.51,1067.16,553.6Z"
        transform="translate(-401.65 -432.6)"
      />
      <path
        strokeMiterlimit="10"
        fill="#fff"
        stroke="#fff"
        strokeWidth="2px"
        className="cls-3"
        d="M1089.87,520a5.57,5.57,0,1,1,5.53,5.53A5.6,5.6,0,0,1,1089.87,520Zm.91,9.81h9.39v35h-9.39Z"
        transform="translate(-401.65 -432.6)"
      />
      <path
        strokeMiterlimit="10"
        fill="#fff"
        stroke="#fff"
        strokeWidth="2px"
        className="cls-3"
        d="M1106.62,529.84h9.18v4.69a12.68,12.68,0,0,1,10.37-5.39c5.54,0,9,2.31,10.79,6.23a13.53,13.53,0,0,1,11.35-6.23c9.89,0,13,6.8,13,15.77v20h-9.39V546.1c0-4.63-1.12-8.06-6.1-8.06-4.62,0-7.15,3.71-7.15,8.34v18.5h-9.39V546.1c0-4.63-1-8.06-6.1-8.06-4.76,0-7.14,4.06-7.14,8.69v18.15h-9.39Z"
        transform="translate(-401.65 -432.6)"
      />
      <path
        strokeMiterlimit="10"
        fill="#fff"
        stroke="#fff"
        strokeWidth="2px"
        className="cls-3"
        d="M1166.18,547.36a18,18,0,0,1,18.15-18.22c9.53,0,17,8.13,17,18.08a31,31,0,0,1-.21,3.36h-25.44c1.12,4.84,5.12,7.15,9.74,7.15a17.38,17.38,0,0,0,9.46-2.73l3.51,6.73a23.64,23.64,0,0,1-13,3.85C1175.15,565.58,1166.18,558.92,1166.18,547.36Zm26.35-3a8.46,8.46,0,0,0-8.48-7.35,8.78,8.78,0,0,0-8.48,7.35Z"
        transform="translate(-401.65 -432.6)"
      />
      <path
        fill="#fff"
        className="cls-2"
        d="M401.65,467.15H431.9l25.5,36c9.5,13.25,29.25,42,29.25,42s20-28.5,29.25-41.75l25.5-36.25h30.25v162h-35V526.65s-6.25,10.25-14.75,22.5L486.65,599.4l-35.5-50.5c-8.25-11.75-14.5-22.25-14.5-22.25v102.5h-35Z"
        transform="translate(-401.65 -432.6)"
      />
      <path
        fill="#fff"
        className="cls-2"
        d="M594.15,566.65c0-36,22-65,56.5-65,14.5,0,30,5.75,39,17.75V504.15H722.4v125H689.65v-15c-8.75,12-24.5,17.5-39,17.5C616.15,631.65,594.15,602.65,594.15,566.65Zm94.75,0c0-19.5-12.5-34-29.75-34-18,0-31,14.5-31,34,0,19.75,13,34,31,34C676.4,600.65,688.9,586.65,688.9,566.65Z"
        transform="translate(-401.65 -432.6)"
      />
      <path
        fill="#fff"
        className="cls-2"
        d="M745.4,464.65h33.5v91.5l43-52h40.75l-48.75,54.5,57,70.5H830.65l-37.5-47.75-14.25,17v30.75H745.4Z"
        transform="translate(-401.65 -432.6)"
      />
      <path
        fill="#fff"
        className="cls-2"
        d="M869.4,566.65c0-36,28-65,64.75-65,34,0,60.75,29,60.75,64.5a110.88,110.88,0,0,1-.75,12H903.4c4,17.25,18.25,25.5,34.75,25.5,12,0,24.5-3.5,33.75-9.75l12.5,24a84.21,84.21,0,0,1-46.5,13.75C901.4,631.65,869.4,607.9,869.4,566.65Zm94-10.75c-2.5-15.75-15-26.25-30.25-26.25-14.5,0-27,10.5-30.25,26.25Z"
        transform="translate(-401.65 -432.6)"
      />
      <path
        fill="#fff"
        className="cls-2"
        d="M1268.51,467.15h121.25v30h-86.25v33H1377V559.9h-73.5v69.25h-35Z"
        transform="translate(-401.65 -432.6)"
      />
      <path
        fill="#fff"
        className="cls-2"
        d="M1399.26,469.15A19.88,19.88,0,1,1,1419,488.9,20,20,0,0,1,1399.26,469.15Zm3.25,35H1436v125h-33.5Z"
        transform="translate(-401.65 -432.6)"
      />
      <path
        fill="#fff"
        className="cls-2"
        d="M1467.51,588.9V531.15h-18.25v-27h18.25v-24l33.5-7.5v31.5h34.5v27H1501V588.4c0,10.75,5.25,14.25,11.25,14.25,8,0,15-4.5,16-5.25l9.5,24.75a57.61,57.61,0,0,1-31.75,9.5C1481,631.65,1467.51,617.15,1467.51,588.9Z"
        transform="translate(-401.65 -432.6)"
      />
    </>
  ),
});
