import {
  FormControl,
  FormHelperText,
  FormLabel,
  Collapse,
  FormErrorMessage,
  Select,
} from "@chakra-ui/react";

interface ISelectWithError {
  helperText: string;
  name: string;
  options: Array<{ _id: string; title: string }>;
  label: string;
  errors: { [key: string]: { message: string } };
  handleChange: () => void;
}

export const SelectWithError = ({
  helperText,
  label,
  options = [],
  name,
  errors,
  handleChange,
}: ISelectWithError) => (
  <FormControl>
    <FormLabel>{label}</FormLabel>
    {!!options.length && (
      <>
        <Select onChange={handleChange}>
          {options.map((_option) => (
            <option key={_option._id} value={_option._id}>
              {_option.title}
            </option>
          ))}
        </Select>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
        <Collapse in={!!errors?.[name]?.message}>
          <FormErrorMessage>
            {errors?.[name] && errors?.[name].message}
          </FormErrorMessage>
        </Collapse>
      </>
    )}
  </FormControl>
);
