import { gql } from "@apollo/client";

export const GET_USERS = gql`
  query getUsers($page: Int, $query: UsersFilterPayload) {
    getUsers(page: $page, query: $query) {
      ... on UsersPayload {
        docs {
          ... on UserPayload {
            _id
            email
            active
            blocked
            profile {
              name {
                pronoun
                prefix
                firstName
                surname
              }
              telephone
              gender {
                disclose
                isSame
                identity
              }
            }
            role {
              name
            }
          }
        }
        hasNextPage
        hasPrevPage
        totalDocs
        totalPages
      }
    }
  }
`;

export const GET_USER = gql`
  query getUser($id: String) {
    getUser(id: $id) {
      ... on UserPayload {
        _id
        email
        profile {
          name {
            pronoun
            prefix
            firstName
            surname
          }
          gender {
            disclose
            isSame
            identity
          }
          telephone
        }
        role {
          name
        }

        blocked
        active
        approved
        settings {
          socials {
            facebook
            linkedin
            twitter
            youtube
            instagram
          }

          subscriptions {
            marketing
          }
          services {
            _id
            title
            price
            time
            description
            active
          }
        }
      }
      ... on GeneralErrorPayload {
        status
        message
      }
    }
  }
`;
