export const muscleGroups = [
  {
    group: 'Chest',
    muscles: ['Pectorals'],
  },
  {
    group: 'Back',
    muscles: [
      'Latissimus Dorsi',
      'Rhomboid',
      'Trapezius',
      'Teres Muscle',
      'Erector Spinae',
    ],
  },
  {
    group: 'Arms',
    muscles: ['Biceps', 'Triceps'],
  },
  {
    group: 'Shoulders',
    muscles: ['Anterior Deltoid', 'Medial Deltoid', 'Posterior Deltoid'],
  },
  {
    group: 'Abdominal',
    muscles: ['Obliques', 'Serratus Anterior', 'Psoas Major'],
  },
  {
    group: 'Glutes',
    muscles: ['Gluteus Medius', 'Glueteus Maximus', 'Gluteals'],
  },
  {
    group: 'Legs',
    muscles: [
      'Hamstrings',
      'Quadriceps: Rectus Femoris',
      'Quadriceps: Vastus Latera',
      'Quadriceps: Vastus Intermedius',
      'Quadriceps: Vastus Medialis',
      'Calves (Gastrocnemius)',
    ],
  },
];
