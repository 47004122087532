import { gql } from "@apollo/client";

export const GET_NOTIFICATIONS = gql`
  query getNotifications($page: Int, $limit: Int) {
    getNotifications(page: $page, limit: $limit) {
      ... on NotificationsPayload {
        __typename
        docs {
          _id
          message
          opened
          type
          user
          link
          createdAt
        }
        totalDocs
        page
        hasNextPage
        hasPrevPage
        hasNew
      }
      ... on GeneralErrorPayload {
        status
        message
      }
    }
  }
`;
