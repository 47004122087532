import { gql } from "@apollo/client";

export const LOGIN = gql`
  mutation login($email: String, $password: String) {
    login(email: $email, password: $password) {
      __typename
      status
      message
      user {
        ... on UserPayload {
          _id
          email
          profile {
            profilePicture
            name {
              firstName
              surname
            }
          }
          role {
            name
            permissions {
              name
            }
          }
        }
      }
    }
  }
`;

export const LOGOUT = gql`
  mutation logout {
    logout {
      status
      message
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation changePassword(
    $oldPassword: String!
    $password: String!
    $passwordRepeat: String!
  ) {
    changePassword(
      oldPassword: $oldPassword
      password: $password
      passwordRepeat: $passwordRepeat
    ) {
      status
      message
    }
  }
`;
