import { ReactNode, useEffect, useRef } from "react";
import { Box } from "@chakra-ui/react";
import { motion } from "framer-motion";

const routesAnimationVariants = {
  hidden: {
    opacity: 0,
    x: "400px",
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: { delay: 0, duration: 0.6 },
  },
  exit: {
    x: "-400px",
    opacity: 0,
    transition: {
      duration: 0.4,
    },
  },
};

export const TabPanelContentWithHeight = ({
  children,
  changeHeight,
}: {
  children: any;
  changeHeight: (height: string) => {};
}) => {
  const currentPanelRef = useRef(document.createElement("div"));
  useEffect(() => {
    changeHeight(currentPanelRef.current.clientHeight + "px");
  }, [changeHeight]);

  return (
    <Box
      as={motion.div}
      variants={routesAnimationVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
      pos="absolute"
      ref={currentPanelRef}
      p={3}
    >
      {children}
    </Box>
  );
};
