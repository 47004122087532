import { gql } from "@apollo/client";

export const UPDATE_SOCIALS = gql`
  mutation updateUserSocials($socials: SocialInput) {
    updateUserSocials(socials: $socials) {
      ... on GeneralResponsePayload {
        status
        message
      }
      ... on GeneralErrorPayload {
        status
        message
      }
    }
  }
`;
