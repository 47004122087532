import { createIcon } from "@chakra-ui/react";

import { brand_100, brand_200 } from "ui";

export const PrimaryWhite = createIcon({
  displayName: "PrimaryWhite",
  viewBox: "0 0 1136.11 219.28",
  // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
  path: (
    <>
      <path
        fill="#84805b"
        stroke="#84805b"
        strokeWidth="5px"
        strokeMiterlimit="10"
        className="cls-1"
        d="M1131.7,649.38a107.14,107.14,0,1,1,107.14-107.14A107.26,107.26,0,0,1,1131.7,649.38Zm0-207.28a100.14,100.14,0,1,0,100.14,100.14A100.26,100.26,0,0,0,1131.7,442.1Z"
        transform="translate(-401.65 -432.6)"
      />
      <path
        fill="#84805b"
        className="cls-2"
        d="M1045.34,542.17V542a3.34,3.34,0,0,0-3.33-3.39h-10.33a3.34,3.34,0,0,0-3.34,3.34v.32a3.35,3.35,0,0,0,3.34,3.34H1042a3.35,3.35,0,0,0,3.34-3.39Z"
        transform="translate(-401.65 -432.6)"
      />
      <path
        fill="#84805b"
        className="cls-2"
        d="M1221.4,538.6a3.34,3.34,0,0,0-3.34,3.39v.22a3.36,3.36,0,0,0,3.34,3.39h10.06a3.34,3.34,0,0,0,3.34-3.34v-.32a3.33,3.33,0,0,0-3.34-3.34Z"
        transform="translate(-401.65 -432.6)"
      />
      <path
        fill="#84805b"
        className="cls-2"
        d="M1197.48,481l7-7a3.47,3.47,0,0,0,0-4.9l0-.05a3.47,3.47,0,0,0-4.9,0l-7,7a3.44,3.44,0,0,0,0,4.89l0,.05A3.46,3.46,0,0,0,1197.48,481Z"
        transform="translate(-401.65 -432.6)"
      />
      <path
        fill="#84805b"
        className="cls-2"
        d="M1065.53,603l-6.63,6.63a3.47,3.47,0,0,0,0,4.9l0,0a3.45,3.45,0,0,0,4.9,0l6.63-6.62a3.47,3.47,0,0,0,0-4.9l0,0A3.46,3.46,0,0,0,1065.53,603Z"
        transform="translate(-401.65 -432.6)"
      />
      <path
        fill="#84805b"
        className="cls-2"
        d="M1131.7,455.81h0a3.43,3.43,0,0,0,3.46-3.41V441.87a3.41,3.41,0,0,0-3.41-3.41h-.18a3.41,3.41,0,0,0-3.41,3.41V452.4a3.43,3.43,0,0,0,3.46,3.41Z"
        transform="translate(-401.65 -432.6)"
      />
      <path
        fill="#84805b"
        className="cls-2"
        d="M1131.7,628.53h0a3.43,3.43,0,0,0-3.46,3.41v10.39a3.41,3.41,0,0,0,3.41,3.41h.18a3.41,3.41,0,0,0,3.41-3.41V631.94a3.43,3.43,0,0,0-3.46-3.41Z"
        transform="translate(-401.65 -432.6)"
      />
      <path
        fill="#84805b"
        className="cls-2"
        d="M1071.3,475.58l-7-7a3.45,3.45,0,0,0-4.9,0l0,0a3.47,3.47,0,0,0,0,4.9l7,7a3.46,3.46,0,0,0,4.88,0l0-.05A3.46,3.46,0,0,0,1071.3,475.58Z"
        transform="translate(-401.65 -432.6)"
      />
      <path
        fill="#84805b"
        className="cls-2"
        d="M1193.35,607.52l7.26,7.26a3.47,3.47,0,0,0,4.9,0l0,0a3.47,3.47,0,0,0,0-4.9l-7.27-7.26a3.46,3.46,0,0,0-4.91,0l0,0A3.46,3.46,0,0,0,1193.35,607.52Z"
        transform="translate(-401.65 -432.6)"
      />
      <path
        strokeMiterlimit="10"
        fill="#fff"
        stroke="#fff"
        strokeWidth="2px"
        className="cls-3"
        d="M1067.16,553.6V537.41h-5.11v-7.57h5.11v-6.73l9.39-2.1v8.83h9.68v7.57h-9.68v16.05c0,3,1.48,4,3.16,4a8.57,8.57,0,0,0,4.48-1.47l2.67,6.94a16.19,16.19,0,0,1-8.9,2.66C1071,565.58,1067.16,561.51,1067.16,553.6Z"
        transform="translate(-401.65 -432.6)"
      />
      <path
        strokeMiterlimit="10"
        fill="#fff"
        stroke="#fff"
        strokeWidth="2px"
        className="cls-3"
        d="M1089.87,520a5.57,5.57,0,1,1,5.53,5.53A5.6,5.6,0,0,1,1089.87,520Zm.91,9.81h9.39v35h-9.39Z"
        transform="translate(-401.65 -432.6)"
      />
      <path
        strokeMiterlimit="10"
        fill="#fff"
        stroke="#fff"
        strokeWidth="2px"
        className="cls-3"
        d="M1106.62,529.84h9.18v4.69a12.68,12.68,0,0,1,10.37-5.39c5.54,0,9,2.31,10.79,6.23a13.53,13.53,0,0,1,11.35-6.23c9.89,0,13,6.8,13,15.77v20h-9.39V546.1c0-4.63-1.12-8.06-6.1-8.06-4.62,0-7.15,3.71-7.15,8.34v18.5h-9.39V546.1c0-4.63-1-8.06-6.1-8.06-4.76,0-7.14,4.06-7.14,8.69v18.15h-9.39Z"
        transform="translate(-401.65 -432.6)"
      />
      <path
        strokeMiterlimit="10"
        fill="#fff"
        stroke="#fff"
        strokeWidth="2px"
        className="cls-3"
        d="M1166.18,547.36a18,18,0,0,1,18.15-18.22c9.53,0,17,8.13,17,18.08a31,31,0,0,1-.21,3.36h-25.44c1.12,4.84,5.12,7.15,9.74,7.15a17.38,17.38,0,0,0,9.46-2.73l3.51,6.73a23.64,23.64,0,0,1-13,3.85C1175.15,565.58,1166.18,558.92,1166.18,547.36Zm26.35-3a8.46,8.46,0,0,0-8.48-7.35,8.78,8.78,0,0,0-8.48,7.35Z"
        transform="translate(-401.65 -432.6)"
      />
      <path
        fill="#fff"
        className="cls-2"
        d="M401.65,467.15H431.9l25.5,36c9.5,13.25,29.25,42,29.25,42s20-28.5,29.25-41.75l25.5-36.25h30.25v162h-35V526.65s-6.25,10.25-14.75,22.5L486.65,599.4l-35.5-50.5c-8.25-11.75-14.5-22.25-14.5-22.25v102.5h-35Z"
        transform="translate(-401.65 -432.6)"
      />
      <path
        fill="#fff"
        className="cls-2"
        d="M594.15,566.65c0-36,22-65,56.5-65,14.5,0,30,5.75,39,17.75V504.15H722.4v125H689.65v-15c-8.75,12-24.5,17.5-39,17.5C616.15,631.65,594.15,602.65,594.15,566.65Zm94.75,0c0-19.5-12.5-34-29.75-34-18,0-31,14.5-31,34,0,19.75,13,34,31,34C676.4,600.65,688.9,586.65,688.9,566.65Z"
        transform="translate(-401.65 -432.6)"
      />
      <path
        fill="#fff"
        className="cls-2"
        d="M745.4,464.65h33.5v91.5l43-52h40.75l-48.75,54.5,57,70.5H830.65l-37.5-47.75-14.25,17v30.75H745.4Z"
        transform="translate(-401.65 -432.6)"
      />
      <path
        fill="#fff"
        className="cls-2"
        d="M869.4,566.65c0-36,28-65,64.75-65,34,0,60.75,29,60.75,64.5a110.88,110.88,0,0,1-.75,12H903.4c4,17.25,18.25,25.5,34.75,25.5,12,0,24.5-3.5,33.75-9.75l12.5,24a84.21,84.21,0,0,1-46.5,13.75C901.4,631.65,869.4,607.9,869.4,566.65Zm94-10.75c-2.5-15.75-15-26.25-30.25-26.25-14.5,0-27,10.5-30.25,26.25Z"
        transform="translate(-401.65 -432.6)"
      />
      <path
        fill="#fff"
        className="cls-2"
        d="M1268.51,467.15h121.25v30h-86.25v33H1377V559.9h-73.5v69.25h-35Z"
        transform="translate(-401.65 -432.6)"
      />
      <path
        fill="#fff"
        className="cls-2"
        d="M1399.26,469.15A19.88,19.88,0,1,1,1419,488.9,20,20,0,0,1,1399.26,469.15Zm3.25,35H1436v125h-33.5Z"
        transform="translate(-401.65 -432.6)"
      />
      <path
        fill="#fff"
        className="cls-2"
        d="M1467.51,588.9V531.15h-18.25v-27h18.25v-24l33.5-7.5v31.5h34.5v27H1501V588.4c0,10.75,5.25,14.25,11.25,14.25,8,0,15-4.5,16-5.25l9.5,24.75a57.61,57.61,0,0,1-31.75,9.5C1481,631.65,1467.51,617.15,1467.51,588.9Z"
        transform="translate(-401.65 -432.6)"
      />
    </>
  ),
});

export const WhiteSecondaryLogo = createIcon({
  displayName: "WhiteSecondaryLogo",
  viewBox: "0 0 562.81 562.81",
  // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
  path: (
    <>
      <path
        fill="#fff"
        d="M820.15 577.53v-42.14h-13.31v-19.71h13.31v-17.51l24.45-5.48v23h25.18v19.71H844.6v41.77c0 7.85 3.83 10.4 8.21 10.4a22.25 22.25 0 0011.67-3.83l6.94 18.06a42 42 0 01-23.17 6.94c-18.25-.01-28.1-10.6-28.1-31.21z"
        transform="translate(-706.76 -266.57)"
      />
      <path
        fill="#fff"
        d="M848.25 609.73c-19 0-29.1-11.14-29.1-32.2v-41.14h-13.31v-21.71h13.31v-17.31l26.45-5.92v23.23h25.18v21.71H845.6v40.77c0 6.15 2.49 9.4 7.21 9.4a21.26 21.26 0 0011.07-3.63l1.06-.79 7.7 20-.67.45a43.2 43.2 0 01-23.72 7.14zm-40.41-75.34h13.31v43.14c0 19.75 9.37 30.2 27.1 30.2a41.26 41.26 0 0022-6.33L864 585.25a23.38 23.38 0 01-11.19 3.31c-8.31 0-9.21-8-9.21-11.4v-42.77h25.18v-17.71H843.6v-22.74l-22.45 5v17.71h-13.31zM879.25 490.14a14.51 14.51 0 1114.42 14.41 14.6 14.6 0 01-14.42-14.41zm2.38 25.54h24.44v91.22h-24.44z"
        transform="translate(-706.76 -266.57)"
      />
      <path
        fill="#fff"
        d="M907.07 607.9h-26.44v-93.22h26.44zm-24.44-2h22.44v-89.22h-22.44zm11-100.35a15.51 15.51 0 1115.59-15.41 15.62 15.62 0 01-15.55 15.41zm0-29a13.51 13.51 0 1013.59 13.6 13.53 13.53 0 00-13.55-13.61zM922.85 515.68h23.9v12.22c5.29-8.2 16.06-14 27-14 14.42 0 23.36 6 28.1 16.23 6.57-10.94 19.16-16.23 29.55-16.23 25.73 0 33.76 17.69 33.76 41v52h-24.45V558c0-12-2.92-21-15.87-21-12 0-18.61 9.67-18.61 21.71v48.19h-24.45V558c0-12-2.74-21-15.87-21-12.41 0-18.61 10.58-18.61 22.62v47.28h-24.45z"
        transform="translate(-706.76 -266.57)"
      />
      <path
        fill="#fff"
        d="M1066.16 607.9h-26.45V558c0-14.19-4.31-20-14.87-20s-17.61 8.13-17.61 20.71v49.19h-26.45V558c0-14.19-4.31-20-14.87-20-8.12 0-17.61 5.66-17.61 21.62v48.28h-26.45v-93.22h25.9v10.17a34.62 34.62 0 0126-12c13.38 0 22.86 5.11 28.21 15.21 7.15-10.49 19.75-15.21 29.44-15.21 23.06 0 34.76 14.14 34.76 42zm-24.45-2h22.45v-51c0-26.95-10.72-40-32.76-40-9.58 0-22.16 4.87-28.69 15.75l-1 1.62-.8-1.71c-4.82-10.39-14-15.66-27.19-15.66-10.4 0-20.92 5.46-26.16 13.59l-1.84 2.85v-14.66h-21.9v89.22h22.48v-46.25c0-11.76 6.06-23.62 19.61-23.62 14.87 0 16.87 11.11 16.87 22v47.87h22.45v-47.16c0-13.59 7.88-22.71 19.61-22.71 15 0 16.87 11.74 16.87 22zM1077.91 561.29c0-26.27 20.44-47.43 47.25-47.43 24.82 0 44.34 21.16 44.34 47.07a80.52 80.52 0 01-.55 8.75h-66.22c2.91 12.59 13.31 18.61 25.35 18.61 8.76 0 17.88-2.55 24.63-7.11l9.13 17.51a61.48 61.48 0 01-33.94 10c-26.63.04-49.99-17.3-49.99-47.4zm68.6-7.84c-1.82-11.5-11-19.16-22.07-19.16-10.59 0-19.71 7.66-22.08 19.16z"
        transform="translate(-706.76 -266.57)"
      />
      <path
        fill="#fff"
        d="M1127.9 609.73c-13.72 0-26.38-4.51-35.64-12.68-10-8.87-15.35-21.23-15.35-35.76 0-27.16 21.2-48.43 48.25-48.43 25 0 45.34 21.56 45.34 48.07a78.34 78.34 0 01-.56 8.88l-.11.87H1104c3 10.43 11.87 16.61 24.07 16.61 8.64 0 17.64-2.59 24.07-6.94l.93-.63 10.07 19.28-.75.49a62.28 62.28 0 01-34.49 10.24zm-2.74-94.87c-25.93 0-46.25 20.39-46.25 46.43 0 13.94 5.08 25.78 14.67 34.26 8.89 7.85 21.08 12.18 34.32 12.18a60.37 60.37 0 0032.63-9.38l-8.19-15.73a46.41 46.41 0 01-24.26 6.67c-13.67 0-23.52-7.24-26.33-19.38l-.28-1.23h66.59c.15-1.48.44-4.64.44-7.75 0-25.41-19.44-46.07-43.34-46.07zm22.52 39.59h-46.55l.25-1.21c2.43-11.74 11.91-20 23.06-20 11.71 0 21.19 8.22 23.06 20zm-44.06-2h41.69a21.1 21.1 0 00-20.87-17.16c-9.81 0-18.22 6.99-20.82 17.16zM854.62 400.07h13.61l11.48 16.21c4.28 6 13.17 18.91 13.17 18.91s9-12.83 13.17-18.8l11.48-16.32h13.62V473h-15.76v-46.14s-2.81 4.61-6.64 10.13l-15.87 22.62-16-22.73c-3.71-5.29-6.53-10-6.53-10V473h-15.73zM941.28 444.87c0-16.21 9.9-29.26 25.43-29.26 6.53 0 13.51 2.58 17.56 8v-6.87H999V473h-14.73v-6.75c-3.94 5.4-11 7.88-17.56 7.88-15.53 0-25.43-13.06-25.43-29.26zm42.65 0c0-8.78-5.62-15.31-13.39-15.31-8.1 0-14 6.53-14 15.31s5.86 15.3 14 15.3c7.77 0 13.39-6.3 13.39-15.3zM1009.37 399h15.08v41.19l19.36-23.41h18.34l-21.94 24.54 25.66 31.68h-18.12l-16.89-21.49-6.41 7.65V473h-15.08zM1065.19 444.87a28.9 28.9 0 0129.15-29.26c15.31 0 27.35 13.05 27.35 29a49.84 49.84 0 01-.34 5.41h-40.85c1.8 7.76 8.21 11.47 15.64 11.47a27.93 27.93 0 0015.19-4.38l5.63 10.8a37.94 37.94 0 01-20.93 6.19c-16.43.03-30.84-10.66-30.84-29.23zm42.32-4.84a13.61 13.61 0 00-13.62-11.82c-6.53 0-12.16 4.73-13.62 11.82zM927.55 634.52h54.58V648H943.3v14.86h33.09v13.39H943.3v31.17h-15.75zM986.41 635.42a8.95 8.95 0 118.89 8.9 9 9 0 01-8.89-8.9zm1.46 15.76H1003v56.27h-15.13zM1017.13 689.33v-26h-8.21v-12.15h8.21v-10.8l15.08-3.38v14.18h15.54v12.16h-15.54v25.77c0 4.84 2.37 6.41 5.07 6.41a13.76 13.76 0 007.2-2.36l4.28 11.14a26 26 0 01-14.29 4.28c-11.26 0-17.34-6.53-17.34-19.25z"
        transform="translate(-706.76 -266.57)"
      />
      <path
        fill="#84805b"
        d="M763.36 547.79v-.47a8.73 8.73 0 00-8.69-8.83h-26.88a8.7 8.7 0 00-8.7 8.7v.81a8.71 8.71 0 008.7 8.7h26.87a8.75 8.75 0 008.7-8.83zM1221.67 538.49a8.73 8.73 0 00-8.7 8.83v.56a8.75 8.75 0 008.7 8.83h26.19a8.7 8.7 0 008.7-8.7v-.82a8.69 8.69 0 00-8.7-8.7zM1159.39 388.41l18.31-18.31a9 9 0 000-12.76l-.13-.13a9 9 0 00-12.75 0l-18.31 18.31a9 9 0 000 12.75l.13.13a9 9 0 0012.75.01zM815.9 706.13l-17.25 17.25a9 9 0 000 12.76l.12.13a9 9 0 0012.76 0L828.79 719a9 9 0 000-12.73l-.13-.13a9 9 0 00-12.76-.01zM988.17 323h.09a8.93 8.93 0 009-8.88v-27.44a8.88 8.88 0 00-8.88-8.88h-.47a8.88 8.88 0 00-8.87 8.88v27.42a8.92 8.92 0 009 8.88zM988.17 772.59h-.11a8.92 8.92 0 00-9 8.88v27a8.87 8.87 0 008.87 8.88h.47a8.87 8.87 0 008.88-8.88v-27a8.93 8.93 0 00-9-8.88zM830.94 374.43l-18.33-18.33a9 9 0 00-12.76 0l-.12.13a9 9 0 000 12.76l18.32 18.32a9 9 0 0012.7 0l.13-.13a9 9 0 00.06-12.75zM1148.64 717.9l18.9 18.9a9 9 0 0012.75 0l.13-.13a9 9 0 000-12.75l-18.9-18.9a9 9 0 00-12.78.05l-.13.13a9 9 0 00.03 12.7z"
        transform="translate(-706.76 -266.57)"
      />
      <path
        fill="#84805b"
        d="M988.17 826.88c-153.79 0-278.91-125.12-278.91-278.91s125.12-278.9 278.91-278.9 278.9 125.12 278.9 278.9S1142 826.88 988.17 826.88zm0-539.59c-143.74 0-260.68 116.94-260.68 260.68s116.94 260.69 260.68 260.69S1248.85 691.72 1248.85 548s-116.94-260.71-260.68-260.71z"
        transform="translate(-706.76 -266.57)"
      />
      <path
        fill="#84805b"
        d="M988.17 829.38C833 829.38 706.76 703.14 706.76 548S833 266.57 988.17 266.57s281.4 126.24 281.4 281.4-126.24 281.41-281.4 281.41zm0-557.81c-152.41 0-276.41 124-276.41 276.4s124 276.41 276.41 276.41 276.4-124 276.4-276.41-123.99-276.4-276.4-276.4zm0 539.59C843.05 811.16 725 693.09 725 548s118.05-263.21 263.17-263.21S1251.35 402.85 1251.35 548s-118.06 263.16-263.18 263.16zm0-521.37C845.8 289.79 730 405.61 730 548s115.8 258.16 258.17 258.16S1246.35 690.34 1246.35 548s-115.82-258.21-258.18-258.21z"
        transform="translate(-706.76 -266.57)"
      />
    </>
  ),
});

export const PurpleSecondaryLogo = createIcon({
  displayName: "PurpleSecondaryLogo",
  viewBox: "0 0 562.81 562.81",
  // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
  path: (
    <>
      <path
        fill="#333889"
        d="M820.15 577.53v-42.14h-13.31v-19.71h13.31v-17.51l24.45-5.48v23h25.18v19.71H844.6v41.77c0 7.85 3.83 10.4 8.21 10.4a22.25 22.25 0 0011.67-3.83l6.94 18.06a42 42 0 01-23.17 6.94c-18.25-.01-28.1-10.6-28.1-31.21z"
        transform="translate(-706.76 -266.57)"
      />
      <path
        fill="#333889"
        d="M848.25 609.73c-19 0-29.1-11.14-29.1-32.2v-41.14h-13.31v-21.71h13.31v-17.31l26.45-5.92v23.23h25.18v21.71H845.6v40.77c0 6.15 2.49 9.4 7.21 9.4a21.26 21.26 0 0011.07-3.63l1.06-.79 7.7 20-.67.45a43.2 43.2 0 01-23.72 7.14zm-40.41-75.34h13.31v43.14c0 19.75 9.37 30.2 27.1 30.2a41.26 41.26 0 0022-6.33L864 585.25a23.38 23.38 0 01-11.19 3.31c-8.31 0-9.21-8-9.21-11.4v-42.77h25.18v-17.71H843.6v-22.74l-22.45 5v17.71h-13.31zM879.25 490.14a14.51 14.51 0 1114.42 14.41 14.6 14.6 0 01-14.42-14.41zm2.38 25.54h24.44v91.22h-24.44z"
        transform="translate(-706.76 -266.57)"
      />
      <path
        fill="#333889"
        d="M907.07 607.9h-26.44v-93.22h26.44zm-24.44-2h22.44v-89.22h-22.44zm11-100.35a15.51 15.51 0 1115.59-15.41 15.62 15.62 0 01-15.55 15.41zm0-29a13.51 13.51 0 1013.59 13.6 13.53 13.53 0 00-13.55-13.61zM922.85 515.68h23.9v12.22c5.29-8.2 16.06-14 27-14 14.42 0 23.36 6 28.1 16.23 6.57-10.94 19.16-16.23 29.55-16.23 25.73 0 33.76 17.69 33.76 41v52h-24.45V558c0-12-2.92-21-15.87-21-12 0-18.61 9.67-18.61 21.71v48.19h-24.45V558c0-12-2.74-21-15.87-21-12.41 0-18.61 10.58-18.61 22.62v47.28h-24.45z"
        transform="translate(-706.76 -266.57)"
      />
      <path
        fill="#333889"
        d="M1066.16 607.9h-26.45V558c0-14.19-4.31-20-14.87-20s-17.61 8.13-17.61 20.71v49.19h-26.45V558c0-14.19-4.31-20-14.87-20-8.12 0-17.61 5.66-17.61 21.62v48.28h-26.45v-93.22h25.9v10.17a34.62 34.62 0 0126-12c13.38 0 22.86 5.11 28.21 15.21 7.15-10.49 19.75-15.21 29.44-15.21 23.06 0 34.76 14.14 34.76 42zm-24.45-2h22.45v-51c0-26.95-10.72-40-32.76-40-9.58 0-22.16 4.87-28.69 15.75l-1 1.62-.8-1.71c-4.82-10.39-14-15.66-27.19-15.66-10.4 0-20.92 5.46-26.16 13.59l-1.84 2.85v-14.66h-21.9v89.22h22.48v-46.25c0-11.76 6.06-23.62 19.61-23.62 14.87 0 16.87 11.11 16.87 22v47.87h22.45v-47.16c0-13.59 7.88-22.71 19.61-22.71 15 0 16.87 11.74 16.87 22zM1077.91 561.29c0-26.27 20.44-47.43 47.25-47.43 24.82 0 44.34 21.16 44.34 47.07a80.52 80.52 0 01-.55 8.75h-66.22c2.91 12.59 13.31 18.61 25.35 18.61 8.76 0 17.88-2.55 24.63-7.11l9.13 17.51a61.48 61.48 0 01-33.94 10c-26.63.04-49.99-17.3-49.99-47.4zm68.6-7.84c-1.82-11.5-11-19.16-22.07-19.16-10.59 0-19.71 7.66-22.08 19.16z"
        transform="translate(-706.76 -266.57)"
      />
      <path
        fill="#333889"
        d="M1127.9 609.73c-13.72 0-26.38-4.51-35.64-12.68-10-8.87-15.35-21.23-15.35-35.76 0-27.16 21.2-48.43 48.25-48.43 25 0 45.34 21.56 45.34 48.07a78.34 78.34 0 01-.56 8.88l-.11.87H1104c3 10.43 11.87 16.61 24.07 16.61 8.64 0 17.64-2.59 24.07-6.94l.93-.63 10.07 19.28-.75.49a62.28 62.28 0 01-34.49 10.24zm-2.74-94.87c-25.93 0-46.25 20.39-46.25 46.43 0 13.94 5.08 25.78 14.67 34.26 8.89 7.85 21.08 12.18 34.32 12.18a60.37 60.37 0 0032.63-9.38l-8.19-15.73a46.41 46.41 0 01-24.26 6.67c-13.67 0-23.52-7.24-26.33-19.38l-.28-1.23h66.59c.15-1.48.44-4.64.44-7.75 0-25.41-19.44-46.07-43.34-46.07zm22.52 39.59h-46.55l.25-1.21c2.43-11.74 11.91-20 23.06-20 11.71 0 21.19 8.22 23.06 20zm-44.06-2h41.69a21.1 21.1 0 00-20.87-17.16c-9.81 0-18.22 6.99-20.82 17.16zM854.62 400.07h13.61l11.48 16.21c4.28 6 13.17 18.91 13.17 18.91s9-12.83 13.17-18.8l11.48-16.32h13.62V473h-15.76v-46.14s-2.81 4.61-6.64 10.13l-15.87 22.62-16-22.73c-3.71-5.29-6.53-10-6.53-10V473h-15.73zM941.28 444.87c0-16.21 9.9-29.26 25.43-29.26 6.53 0 13.51 2.58 17.56 8v-6.87H999V473h-14.73v-6.75c-3.94 5.4-11 7.88-17.56 7.88-15.53 0-25.43-13.06-25.43-29.26zm42.65 0c0-8.78-5.62-15.31-13.39-15.31-8.1 0-14 6.53-14 15.31s5.86 15.3 14 15.3c7.77 0 13.39-6.3 13.39-15.3zM1009.37 399h15.08v41.19l19.36-23.41h18.34l-21.94 24.54 25.66 31.68h-18.12l-16.89-21.49-6.41 7.65V473h-15.08zM1065.19 444.87a28.9 28.9 0 0129.15-29.26c15.31 0 27.35 13.05 27.35 29a49.84 49.84 0 01-.34 5.41h-40.85c1.8 7.76 8.21 11.47 15.64 11.47a27.93 27.93 0 0015.19-4.38l5.63 10.8a37.94 37.94 0 01-20.93 6.19c-16.43.03-30.84-10.66-30.84-29.23zm42.32-4.84a13.61 13.61 0 00-13.62-11.82c-6.53 0-12.16 4.73-13.62 11.82zM927.55 634.52h54.58V648H943.3v14.86h33.09v13.39H943.3v31.17h-15.75zM986.41 635.42a8.95 8.95 0 118.89 8.9 9 9 0 01-8.89-8.9zm1.46 15.76H1003v56.27h-15.13zM1017.13 689.33v-26h-8.21v-12.15h8.21v-10.8l15.08-3.38v14.18h15.54v12.16h-15.54v25.77c0 4.84 2.37 6.41 5.07 6.41a13.76 13.76 0 007.2-2.36l4.28 11.14a26 26 0 01-14.29 4.28c-11.26 0-17.34-6.53-17.34-19.25z"
        transform="translate(-706.76 -266.57)"
      />
      <path
        fill="#84805b"
        d="M763.36 547.79v-.47a8.73 8.73 0 00-8.69-8.83h-26.88a8.7 8.7 0 00-8.7 8.7v.81a8.71 8.71 0 008.7 8.7h26.87a8.75 8.75 0 008.7-8.83zM1221.67 538.49a8.73 8.73 0 00-8.7 8.83v.56a8.75 8.75 0 008.7 8.83h26.19a8.7 8.7 0 008.7-8.7v-.82a8.69 8.69 0 00-8.7-8.7zM1159.39 388.41l18.31-18.31a9 9 0 000-12.76l-.13-.13a9 9 0 00-12.75 0l-18.31 18.31a9 9 0 000 12.75l.13.13a9 9 0 0012.75.01zM815.9 706.13l-17.25 17.25a9 9 0 000 12.76l.12.13a9 9 0 0012.76 0L828.79 719a9 9 0 000-12.73l-.13-.13a9 9 0 00-12.76-.01zM988.17 323h.09a8.93 8.93 0 009-8.88v-27.44a8.88 8.88 0 00-8.88-8.88h-.47a8.88 8.88 0 00-8.87 8.88v27.42a8.92 8.92 0 009 8.88zM988.17 772.59h-.11a8.92 8.92 0 00-9 8.88v27a8.87 8.87 0 008.87 8.88h.47a8.87 8.87 0 008.88-8.88v-27a8.93 8.93 0 00-9-8.88zM830.94 374.43l-18.33-18.33a9 9 0 00-12.76 0l-.12.13a9 9 0 000 12.76l18.32 18.32a9 9 0 0012.7 0l.13-.13a9 9 0 00.06-12.75zM1148.64 717.9l18.9 18.9a9 9 0 0012.75 0l.13-.13a9 9 0 000-12.75l-18.9-18.9a9 9 0 00-12.78.05l-.13.13a9 9 0 00.03 12.7z"
        transform="translate(-706.76 -266.57)"
      />
      <path
        fill="#84805b"
        d="M988.17 826.88c-153.79 0-278.91-125.12-278.91-278.91s125.12-278.9 278.91-278.9 278.9 125.12 278.9 278.9S1142 826.88 988.17 826.88zm0-539.59c-143.74 0-260.68 116.94-260.68 260.68s116.94 260.69 260.68 260.69S1248.85 691.72 1248.85 548s-116.94-260.71-260.68-260.71z"
        transform="translate(-706.76 -266.57)"
      />
      <path
        fill="#84805b"
        d="M988.17 829.38C833 829.38 706.76 703.14 706.76 548S833 266.57 988.17 266.57s281.4 126.24 281.4 281.4-126.24 281.41-281.4 281.41zm0-557.81c-152.41 0-276.41 124-276.41 276.4s124 276.41 276.41 276.41 276.4-124 276.4-276.41-123.99-276.4-276.4-276.4zm0 539.59C843.05 811.16 725 693.09 725 548s118.05-263.21 263.17-263.21S1251.35 402.85 1251.35 548s-118.06 263.16-263.18 263.16zm0-521.37C845.8 289.79 730 405.61 730 548s115.8 258.16 258.17 258.16S1246.35 690.34 1246.35 548s-115.82-258.21-258.18-258.21z"
        transform="translate(-706.76 -266.57)"
      />
    </>
  ),
});

export const PrimaryLogo = createIcon({
  displayName: "PrimaryLogo",
  viewBox: "0 0 1136.11 219.28",
  // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
  path: (
    <>
      <path
        fill={brand_100}
        stroke={brand_100}
        strokeMiterlimit="10"
        strokeWidth="5"
        d="M730.05 216.78a107.14 107.14 0 11107.14-107.14 107.26 107.26 0 01-107.14 107.14zm0-207.28a100.14 100.14 0 10100.14 100.14A100.26 100.26 0 00730.05 9.5z"
      />
      <path
        d="M1045.34 542.17V542a3.34 3.34 0 00-3.33-3.39h-10.33a3.34 3.34 0 00-3.34 3.34v.32a3.35 3.35 0 003.34 3.34H1042a3.35 3.35 0 003.34-3.39zM1221.4 538.6a3.34 3.34 0 00-3.34 3.39v.22a3.36 3.36 0 003.34 3.39h10.06a3.34 3.34 0 003.34-3.34v-.32a3.33 3.33 0 00-3.34-3.34zM1197.48 481l7-7a3.47 3.47 0 000-4.9v-.05a3.47 3.47 0 00-4.9 0l-7 7a3.44 3.44 0 000 4.89v.05a3.46 3.46 0 004.9.01zM1065.53 603l-6.63 6.63a3.47 3.47 0 000 4.9 3.45 3.45 0 004.9 0l6.63-6.62a3.47 3.47 0 000-4.9 3.46 3.46 0 00-4.9-.01zM1131.7 455.81a3.43 3.43 0 003.46-3.41v-10.53a3.41 3.41 0 00-3.41-3.41h-.18a3.41 3.41 0 00-3.41 3.41v10.53a3.43 3.43 0 003.46 3.41zM1131.7 628.53a3.43 3.43 0 00-3.46 3.41v10.39a3.41 3.41 0 003.41 3.41h.18a3.41 3.41 0 003.41-3.41v-10.39a3.43 3.43 0 00-3.46-3.41zM1071.3 475.58l-7-7a3.45 3.45 0 00-4.9 0 3.47 3.47 0 000 4.9l7 7a3.46 3.46 0 004.88 0v-.05a3.46 3.46 0 00.02-4.85zM1193.35 607.52l7.26 7.26a3.47 3.47 0 004.9 0 3.47 3.47 0 000-4.9l-7.27-7.26a3.46 3.46 0 00-4.91 0 3.46 3.46 0 00.02 4.9z"
        fill={brand_100}
        className="cls-2"
        transform="translate(-401.65 -432.6)"
      />
      <path
        d="M1067.16 553.6v-16.19h-5.11v-7.57h5.11v-6.73l9.39-2.1v8.83h9.68v7.57h-9.68v16.05c0 3 1.48 4 3.16 4a8.57 8.57 0 004.48-1.47l2.67 6.94a16.19 16.19 0 01-8.9 2.66c-6.96-.01-10.8-4.08-10.8-11.99zM1089.87 520a5.57 5.57 0 115.53 5.53 5.6 5.6 0 01-5.53-5.53zm.91 9.81h9.39v35h-9.39zM1106.62 529.84h9.18v4.69a12.68 12.68 0 0110.37-5.39c5.54 0 9 2.31 10.79 6.23a13.53 13.53 0 0111.35-6.23c9.89 0 13 6.8 13 15.77v20h-9.39V546.1c0-4.63-1.12-8.06-6.1-8.06-4.62 0-7.15 3.71-7.15 8.34v18.5h-9.39V546.1c0-4.63-1-8.06-6.1-8.06-4.76 0-7.14 4.06-7.14 8.69v18.15h-9.39zM1166.18 547.36a18 18 0 0118.15-18.22c9.53 0 17 8.13 17 18.08a31 31 0 01-.21 3.36h-25.44c1.12 4.84 5.12 7.15 9.74 7.15a17.38 17.38 0 009.46-2.73l3.51 6.73a23.64 23.64 0 01-13 3.85c-10.24 0-19.21-6.66-19.21-18.22zm26.35-3a8.46 8.46 0 00-8.48-7.35 8.78 8.78 0 00-8.48 7.35z"
        strokeMiterlimit="10"
        fill={brand_200}
        stroke={brand_200}
        strokeWidth="2px"
        className="cls-3"
        transform="translate(-401.65 -432.6)"
      />
      <path
        d="M401.65 467.15h30.25l25.5 36c9.5 13.25 29.25 42 29.25 42s20-28.5 29.25-41.75l25.5-36.25h30.25v162h-35v-102.5s-6.25 10.25-14.75 22.5l-35.25 50.25-35.5-50.5c-8.25-11.75-14.5-22.25-14.5-22.25v102.5h-35zM594.15 566.65c0-36 22-65 56.5-65 14.5 0 30 5.75 39 17.75v-15.25h32.75v125h-32.75v-15c-8.75 12-24.5 17.5-39 17.5-34.5 0-56.5-29-56.5-65zm94.75 0c0-19.5-12.5-34-29.75-34-18 0-31 14.5-31 34 0 19.75 13 34 31 34 17.25 0 29.75-14 29.75-34zM745.4 464.65h33.5v91.5l43-52h40.75l-48.75 54.5 57 70.5h-40.25l-37.5-47.75-14.25 17v30.75h-33.5zM869.4 566.65c0-36 28-65 64.75-65 34 0 60.75 29 60.75 64.5a110.88 110.88 0 01-.75 12H903.4c4 17.25 18.25 25.5 34.75 25.5 12 0 24.5-3.5 33.75-9.75l12.5 24a84.21 84.21 0 01-46.5 13.75c-36.5 0-68.5-23.75-68.5-65zm94-10.75c-2.5-15.75-15-26.25-30.25-26.25-14.5 0-27 10.5-30.25 26.25zM1268.51 467.15h121.25v30h-86.25v33H1377v29.75h-73.5v69.25h-35zM1399.26 469.15A19.88 19.88 0 111419 488.9a20 20 0 01-19.74-19.75zm3.25 35H1436v125h-33.5zM1467.51 588.9v-57.75h-18.25v-27h18.25v-24l33.5-7.5v31.5h34.5v27H1501v57.25c0 10.75 5.25 14.25 11.25 14.25 8 0 15-4.5 16-5.25l9.5 24.75a57.61 57.61 0 01-31.75 9.5c-25 0-38.49-14.5-38.49-42.75z"
        fill={brand_100}
        className="cls-2"
        transform="translate(-401.65 -432.6)"
      />
    </>
  ),
});
