import { useRouter } from "next/navigation";
import { useState } from "react";
import { Button, VStack } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

// Context & components
import { useAuth, PasswordField, FormInput } from "ui";

// Consts
import { validationMessage, validationRegex } from "consts";

const fields = [
  {
    name: "email",
    label: "Email",
    rules: {
      required: validationMessage.required,
      pattern: {
        value: validationRegex.email,
        message: validationMessage.invalidEmail,
      },
    },
  },
  {
    name: "password",
    label: "Password",
    rules: {
      required: validationMessage.required,
    },
  },
];

export const LoginForm = ({
  resetPassword,
  isAdmin = false,
}: {
  resetPassword: boolean;
  isAdmin?: boolean;
}) => {
  const { handleLogin, refetchUser } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const router = useRouter();
  const { handleSubmit, control, formState } = useForm();

  const onSubmit = async (values: any) => {
    setIsLoading(true);
    const login = await handleLogin(values);
    let { message, status } = login;
    if (status !== 200) {
      setIsLoading(false);
      console.error(`Error logging in with code ${status}: ${message}`);
      toast.error(message);
      return;
    } else {
      if (!isAdmin) router.push(process.env.NEXT_PUBLIC_DASHBOARD_URL ?? "");
      else refetchUser();
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack gap={4} alignItems={"flex-start"}>
        <FormInput
          name="email"
          label="Email"
          errors={formState.errors}
          rules={fields[0].rules}
          control={control}
          type="email"
        />
        <PasswordField
          resetPassword={resetPassword}
          errors={formState.errors}
          rules={fields[1].rules}
          control={control}
        />
        <Button isLoading={isLoading} type="submit" size="sm" fontSize="md">
          Sign in
        </Button>
      </VStack>
    </form>
  );
};
