import { gql } from "@apollo/client";

export const GET_LOGS = gql`
  query getLogs($user: ID, $page: Int, $limit: Int) {
    getLogs(user: $user, page: $page, limit: $limit) {
      ... on LogsPayload {
        __typename
        docs {
          _id
          type
          message
          link
          opened
          error
          createdAt
          system
          severity
        }
        totalDocs
        page
        hasNextPage
        hasPrevPage
        hasNew
      }
      ... on GeneralErrorPayload {
        status
        message
      }
    }
  }
`;
