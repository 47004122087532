export const GOD = 'GOD',
  SYSTEM_ADMIN = 'System Admin',
  ADMIN = 'Admin',
  CLIENT = 'Client',
  TRAINER = 'Trainer';

export const roles = [GOD, SYSTEM_ADMIN, ADMIN, CLIENT, TRAINER];

export const systemAdminRoles = [GOD, SYSTEM_ADMIN];
export const adminRoles = [...systemAdminRoles, ADMIN];
export const notClientRoles = [GOD, SYSTEM_ADMIN, ADMIN, TRAINER];
