import { extendTheme } from "@chakra-ui/react";

import { fonts } from "./fonts";
import { sizes } from "./sizes";
import { colors } from "./colors";

//* Components
import * as components from "./components";

export const theme = extendTheme({
  colors,
  fonts,
  sizes,
  components: { ...components },
});
