import NextLink from "next/link";
import {
  Container,
  Box,
  Flex,
  Grid,
  GridItem,
  Icon,
  chakra,
  Link,
} from "@chakra-ui/react";
import { WhiteSecondaryLogo } from "ui";

const FooterBottom = ({}) => (
  <Box bg="brand.100" color="white" py={5}>
    <Container maxW="container.90">
      <Grid
        justifyContent="space-between"
        alignItems={{ base: "flex-start", lg: "center" }}
        w="full"
        templateColumns={{ base: "1fr", lg: "1fr 1fr" }}
        gap={{ base: 1, lg: 2 }}
      >
        <GridItem order={{ base: 3, lg: 1 }}>
          <FooterCopyright />
        </GridItem>
        <GridItem order={2}>
          <FooterLegal />
        </GridItem>
        <GridItem display={{ base: "block", lg: "none" }} order={1}>
          <Icon w={100} h={100} as={WhiteSecondaryLogo} />
        </GridItem>
      </Grid>
    </Container>
  </Box>
);

export default FooterBottom;

const FooterCopyright = () => (
  <Flex
    gap={{ base: 0, lg: 2 }}
    flexDirection={{ base: "column", lg: "row" }}
    justifyContent={"flex-start"}
    alignItems={{ base: "flex-start", lg: "center" }}
  >
    <chakra.p color="white">
      Copyright &copy; {new Date().getFullYear()} Make Time Fit Ltd
    </chakra.p>
    {/* <chakra.a
      m={0}
      href="https://sophiewalkerdesign.com"
      target="_blank"
      rel="noopener noreferrer nofollow"
    >
      Design by Sophie Walker
    </chakra.a> */}
  </Flex>
);

const legalPages = [
  {
    title: "Cookie Policy",
    link: "/legal/cookie-policy",
  },
  {
    title: "Terms & Conditions",
    link: "/legal/terms-and-conditions",
  },
  {
    title: "Privacy Policy",
    link: "/legal/privacy-policy",
  },
];

const FooterLegal = () => (
  <Flex
    gap={{ base: 1, lg: 2 }}
    flexDirection={{ base: "column", lg: "row" }}
    justifyContent={{ base: "flex-start", lg: "flex-end" }}
    alignItems={{ base: "flex-start", lg: "center" }}
  >
    {legalPages.map((page) => (
      <Link as={NextLink} href={page.link} key={page.title}>
        {page.title}
      </Link>
    ))}
  </Flex>
);
