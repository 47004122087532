import { gql } from "@apollo/client";

export const GET_SESSION_USER = gql`
  query getUserBySession {
    getUserBySession {
      ... on UserPayload {
        _id
        email
        profile {
          profilePicture
          name {
            firstName
            surname
          }
          gender {
            disclose
            isSame
            identity
          }
        }
        role {
          name
          permissions {
            name
          }
        }
      }
    }
  }
`;

export const GET_USER_ROLES = gql`
  query {
    roles {
      ... on RolesPayload {
        roles {
          _id
          name
        }
      }
      ... on GeneralErrorPayload {
        status
        message
      }
    }
  }
`;
