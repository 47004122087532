//* Imports
import { useState } from "react";
import NextLink from "next/link";
import {
  chakra,
  HStack,
  Box,
  Icon,
  Button,
  VStack,
  MenuButton,
  Menu,
  MenuItem,
  MenuList,
  Link,
} from "@chakra-ui/react";
import { BsCalendar, BsPerson } from "react-icons/bs";
import { BiCog, BiLogOut } from "react-icons/bi";

//* UI
import {
  PageContainer,
  PrimaryWhite,
  ProfileIcon,
  useAuth,
  useNotifications,
} from "ui";

export const Header = ({}) => {
  const { auth, handleLogout } = useAuth();
  const [openNotifications, setOpenNotifications] = useState(false);
  const { notifications } = useNotifications();

  return (
    <>
      <chakra.header
        w="100vw"
        bg="brand.100"
        py={{ base: "15px", lg: "unset" }}
      >
        <PageContainer py={15}>
          <HStack justifyContent="space-between">
            <VStack alignItems="flex-start">
              <Link
                as={NextLink}
                href={process.env.NEXT_PUBLIC_WEBSITE_URL}
                passHref
              >
                <Icon
                  as={PrimaryWhite}
                  w={{ base: 150, md: 200 }}
                  h={{ base: 25, md: 75 }}
                />
              </Link>
            </VStack>
            <Box>
              {auth?._id ? (
                <>
                  {/* <IconButton
                      size="lg"
                      variant="ghost"
                      aria-label="open notifications"
                      icon={<FiBell color="white" />}
                      onClick={() => setOpenNotifications(true)}
                    /> */}
                  <Menu>
                    <MenuButton as={Button}>{auth.name.firstName}</MenuButton>
                    <MenuList>
                      <Link
                        as={NextLink}
                        href={`${process.env.NEXT_PUBLIC_DASHBOARD_URL}`}
                      >
                        <MenuItem icon={<BsPerson />}>Profile</MenuItem>
                      </Link>
                      <Link
                        as={NextLink}
                        href={`${process.env.NEXT_PUBLIC_DASHBOARD_URL}/planner`}
                      >
                        <MenuItem icon={<BsCalendar />}>Planner</MenuItem>
                      </Link>
                      <Link
                        as={NextLink}
                        href={`${process.env.NEXT_PUBLIC_DASHBOARD_URL}/settings`}
                      >
                        <MenuItem icon={<BiCog />}>Settings</MenuItem>
                      </Link>
                      <MenuItem icon={<BiLogOut />} onClick={handleLogout}>
                        Logout
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </>
              ) : (
                <Link
                  as={NextLink}
                  href={`${process.env.NEXT_PUBLIC_WEBSITE_URL}login`}
                  passHref
                >
                  <Button
                    leftIcon={<Icon as={ProfileIcon} mr={1} w={4} h={4} />}
                  >
                    Login
                  </Button>
                </Link>
              )}
            </Box>
          </HStack>
        </PageContainer>
      </chakra.header>
      {/* <NotificationsDrawer
        isOpen={openNotifications}
        onClose={() => setOpenNotifications(false)}
      /> */}
    </>
  );
};
