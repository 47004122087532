import { createIcon } from '@chakra-ui/react';

export const HistoryIcon = createIcon({
  displayName: 'HistoryIcon',
  viewBox: '0 0 100 100',
  // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
  path: (
    <g transform="translate(0,-952.36218)">
      <path
        fill="currentColor"
        d="M52.6,971.4c-13.7,0-25.4,8.7-29.7,20.9l-3.2-5.7c-0.4-0.7-1.2-1.1-2-1c-1.1,0.1-1.9,1.1-1.8,2.2
		c0,0.3,0.1,0.5,0.3,0.8l5.5,9.8c0.5,0.9,1.7,1.3,2.7,0.8l9.8-5c1-0.5,1.4-1.6,1-2.7c-0.5-1-1.6-1.4-2.7-1c0,0-0.1,0-0.1,0.1
		l-5.6,2.9c3.7-10.5,13.9-18.1,25.9-18.1c15.2,0,27.4,12.1,27.4,27s-12.2,27-27.4,27c-13,0-23.8-8.9-26.7-20.8
		c-0.2-1.1-1.2-1.8-2.3-1.7s-1.8,1.2-1.7,2.3c0,0.1,0,0.2,0.1,0.3c3.3,13.7,15.7,23.8,30.5,23.8c17.3,0,31.4-13.9,31.4-31
		C84,985.3,69.9,971.4,52.6,971.4z M52.8,984.3c-1,0.1-1.8,1-1.8,2v16c0,0.6,0.3,1.2,0.8,1.6l10,8c0.9,0.7,2.1,0.6,2.8-0.3
		c0.7-0.9,0.6-2.1-0.3-2.8l0,0l-9.2-7.4v-15c0-1.1-0.9-2-2-2C53,984.3,52.9,984.3,52.8,984.3z"
      />
    </g>
  ),
});
