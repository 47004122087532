import { Dropdown } from "ui";

const identityArray = [
  { label: "Male" },
  { label: "Female" },
  { label: "Transgender" },
  { label: "Non-binary" },
  { label: "Prefer not to say" },
];

export const GenderIdentity = (props: any) => (
  <Dropdown options={identityArray} {...props} />
);
