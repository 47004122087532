import {
  ApolloClient,
  DocumentNode,
  InMemoryCache,
  TypedDocumentNode,
} from "@apollo/client";
import { setContext } from "apollo-link-context";
import { ApolloLink } from "apollo-link";
import { createUploadLink } from "apollo-upload-client";
import { onError } from "@apollo/client/link/error";

const isProduction = ["prod", "production"].includes(
  process.env.NODE_ENV ?? "development"
);

// Log any GraphQL errors or network error that occurred
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors && !isProduction)
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );
    });
  if (networkError && !isProduction) {
    console.error(`[Network error]: ${networkError}`);
  }
});

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
    },
  };
});

const uploadLink = createUploadLink({
  uri: process.env.NEXT_PUBLIC_API_URL,
  credentials: "include",
});

// @ts-ignore
const isServer = typeof window === "undefined";

const client = new ApolloClient({
  ssrMode: isServer,
  cache: new InMemoryCache(),
  fetchOptions: {
    mode: "no-cors",
  },
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "network-only",
    },
  },

  //@ts-ignore
  link: ApolloLink.from([errorLink, authLink, uploadLink]),
});

export const fetcher = async ({
  query,
  variables,
}: {
  query: DocumentNode | TypedDocumentNode<any, any>;
  variables: any;
}) => client.query({ query, variables });

export default client;
