import { darken, lighten } from "polished";

import { brand_700 } from "../colors";

export const Button = {
  baseStyle: {
    fontWeight: "bold",
    borderRadius: "base",
    wordBreak: "break-all",
    maxWidth: "100%",
    _active: {
      bg: lighten(0.2, "#84805b"),
      outline: "none",
      boxShadow: "none",
    },
    _focus: {
      outline: `2px solid ${brand_700}`,
      boxShadow: "none",
    },
    _disabled: {
      _hover: {
        bg: "red",
      },
    },
  },

  sizes: {
    sm: {
      fontSize: "sm",
      px: 4,
      py: 3,
    },
    md: {
      fontSize: "md",
      px: 6,
      py: 4,
    },
    lg: {
      fontSize: "lg",
      px: 8,
      py: 8,
    },
    xl: {
      fontSize: 22,
      px: 10,
      py: 6,
    },
  },

  variants: {
    icon: {
      size: "sm",
      bg: "none",
      outline: "none",
      border: "none",
      padding: 0,
      _hover: {
        outline: "none",
        bg: "none",
      },
      _active: {
        bg: "none",
        outline: "none",
      },
    },
    solid: {
      bg: "brand.200",
      color: "#fff",
      _hover: {
        //- brand.200
        bg: lighten(0.2, "#84805b"),
      },
      _active: {
        bg: lighten(0.2, "#84805b"),
      },
    },
    invisible: {
      border: "none",
      outline: "none",
      bg: "none",
    },
    outline: {
      border: "1px solid",
      borderColor: "brand.100",
      color: "brand.100",
      _hover: {
        //- brand.100
        bg: darken(0.1, "#fff"),
      },
    },
    empty: {
      _hover: {
        //- brand.100
        bg: darken(0.1, "#fff"),
      },
      _active: {
        bg: darken(0.3, "#fff"),
      },
      textDecoration: "underline",
      _text: {},
      fontSize: 14,
      fontWeight: "normal",
      textTransform: "unset",
    },
  },

  defaultProps: {
    size: "md",
    variant: "solid",
  },
};
