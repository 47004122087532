import { gql } from '@apollo/client';

export const MARK_AS_READ = gql`
  mutation toggleOpened($id: String!) {
    toggleOpened(id: $id) {
      ... on NotificationPayload {
        _id
        opened
        hasNew
      }
      ... on GeneralErrorPayload {
        status
        message
      }
    }
  }
`;

export const MARK_ALL_AS_READ = gql`
  mutation markAllAsRead {
    markAllAsRead {
      ... on GeneralResponsePayload {
        __typename
        status
        message
      }
      ... on GeneralErrorPayload {
        status
        message
      }
    }
  }
`;

export const DELETE_NOTIFICATION = gql`
  mutation deleteNotification($id: String!) {
    deleteNotification(id: $id) {
      ... on NotificationPayload {
        status
        message
        hasNew
      }
      ... on GeneralErrorPayload {
        status
        message
      }
    }
  }
`;
