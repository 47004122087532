import { memo } from "react";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Collapse,
  Flex,
} from "@chakra-ui/react";
import { useController, FieldErrors, FieldValues } from "react-hook-form";

import { renderInput } from "./InputTypes";

interface IFormInput {
  id?: string;
  name: string;
  label: string;
  // errors: FieldErrors<FieldValues>;
  errors: any;
  canedit?: boolean;
  control: any;
  rules: any;
  props?: any;
  type: string;
}

export const FormInput = ({
  id,
  name,
  label,
  errors,
  canedit,
  control,
  rules,
  type = "text",
  ...props
}: IFormInput) => {
  const { field } = useController({
    name,
    control,
    rules,
  });

  return (
    <FormControl>
      <Flex
        justifyContent="space-between"
        alignItems={{ md: "center" }}
        w="full"
        flexDirection={{ base: "column", md: "row" }}
      >
        <FormLabel htmlFor={name}>{label}</FormLabel>
        {renderInput(type, {
          label,
          errors,
          canedit,
          control,
          rules,
          w: 250,
          ...props,
          ...field,
        })}
      </Flex>
      <Collapse in={!!errors?.[name]?.message}>
        <FormErrorMessage>
          {errors?.[name] && errors?.[name]?.message}
        </FormErrorMessage>
      </Collapse>
    </FormControl>
  );
};
