import { gql } from "@apollo/client";

export const GET_USER_PLANNER = gql`
  query userBookings($user: ID!, $start: String!, $end: String!) {
    userBookings(user: $user, start: $start, end: $end) {
      ... on PlannerPayload {
        bookings {
          _id
          client {
            ... on UserPayload {
              _id
              profile {
                name {
                  firstName
                  surname
                }
              }
            }
          }
          start
          end
          # location {
          #   name
          #   lineOne
          #   city
          #   postcode
          # }
          service {
            title
            price
          }
          status
          stripe {
            paymentIntentId
            chargeId
            refundId
          }
        }
      }
      ... on GeneralErrorPayload {
        message
        status
      }
    }
  }
`;

export const GET_MY_BOOKINGS = gql`
  query myBookings($start: String!, $end: String!) {
    myBookings(start: $start, end: $end) {
      ... on PlannerPayload {
        bookings {
          _id
          client {
            ... on UserPayload {
              _id
              profile {
                name {
                  firstName
                  surname
                }
              }
            }
          }
          start
          end
          # location {
          #   name
          #   lineOne
          #   city
          #   postcode
          # }
          service {
            title
            price
          }
          status
          stripe {
            paymentIntentId
            chargeId
            refundId
          }
        }
      }
      ... on GeneralErrorPayload {
        message
        status
      }
    }
  }
`;
