import { createIcon } from '@chakra-ui/react';

export const SearchIcon = createIcon({
  displayName: 'SearchIcon',
  viewBox: '0 0 100 100',
  // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
  path: (
    <g>
      <path
        fill="currentColor"
        d="M25.9,25.9c-3.9,3.9-6.1,9.1-6.1,14.7s2.2,10.8,6.1,14.7c3.9,3.9,9.1,6.1,14.7,6.1c5.6,0,10.8-2.2,14.7-6.1
		s6.1-9.1,6.1-14.7c0-5.6-2.2-10.8-6.1-14.7C47.2,17.8,34.1,17.8,25.9,25.9z M57.3,40.6c0,4.5-1.7,8.7-4.9,11.8
		c-6.5,6.5-17.1,6.5-23.6,0c-3.2-3.1-4.9-7.3-5-11.7c0-4.5,1.7-8.7,5-11.9c3.1-3.1,7.3-4.9,11.8-4.9c4.5,0,8.7,1.7,11.8,4.9
		C55.6,31.9,57.3,36.1,57.3,40.6z"
      />
      <path
        fill="currentColor"
        d="M87.4,84.7c1.1-2.2,0.7-5-1.1-6.8L72.6,64.2c-0.8-0.8-2.1-0.8-2.9,0l-1,1l-6.1-6.1c9.5-11.2,9-28.1-1.6-38.7
		c-11.2-11.2-29.3-11.2-40.5,0s-11.2,29.3,0,40.5c5.6,5.6,12.9,8.4,20.3,8.4c6.8,0,13.6-2.4,19-7.2l6.1,6.1l-1.5,1.5
		c-0.8,0.8-0.8,2.1,0,2.9l13.7,13.7c1.1,1.1,2.4,1.5,3.8,1.5c1.2,0,2.4-0.4,3.4-0.9c0.2,0,0.4-0.1,0.6-0.2c0.2-0.1,0.5-0.2,0.7-0.4
		l1.9-1.9L87.4,84.7z M80.8,83.4L68.5,71.1l2.6-2.6l12.2,12.2c0.7,0.7,0.7,1.9,0,2.6C82.6,84.1,81.5,84.1,80.8,83.4z M65.1,40.6
		c0,6.5-2.6,12.7-7.2,17.3c-9.6,9.6-25.1,9.6-34.7,0c-9.5-9.6-9.5-25.1,0-34.7c4.6-4.6,10.8-7.2,17.3-7.2s12.7,2.6,17.3,7.2
		C62.6,27.9,65.1,34.1,65.1,40.6z"
      />
      <path
        fill="currentColor"
        d="M40.6,27.6L40.6,27.6c-7.2,0-13,5.8-13,13c0,1.2,0.9,2.1,2,2.1c1.1,0,2.1-1,2.1-2c0-4.9,4-8.9,8.9-8.9
		c1.2,0,2.1-0.9,2.1-2C42.7,28.6,41.8,27.7,40.6,27.6z"
      />
    </g>
  ),
});
