import {
  Select,
  Collapse,
  FormErrorMessage,
  FormLabel,
  FormControl,
  Flex,
} from "@chakra-ui/react";
import { useController } from "react-hook-form";

interface IDropdown {
  id: string;
  name: string;
  label: string;
  errors: { [key: string]: { message: string } };
  canedit: boolean;
  options: Array<{ label: string; value: string }>;
  control: any;
  rules: any;
  props: any;
}

export const Dropdown = ({
  id,
  control,
  label,
  rules,
  canedit,
  name,
  errors,
  options,
  ...props
}: IDropdown) => {
  const { field } = useController({
    name,
    control,
    rules,
  });
  return (
    <FormControl>
      <Flex w="full" alignItems="center" justifyContent="space-between">
        <FormLabel htmlFor={name}>{label}</FormLabel>
        <Select maxW={250} id={id} isDisabled={canedit} {...field} {...props}>
          {options.map(({ label, value }, index) => (
            <option key={value || index.toString()} value={value || label}>
              {label}
            </option>
          ))}
        </Select>
      </Flex>

      <Collapse in={!!errors?.[name]?.message}>
        <FormErrorMessage>
          {errors[name] && errors[name].message}
        </FormErrorMessage>
      </Collapse>
    </FormControl>
  );
};
