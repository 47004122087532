import { createIcon } from '@chakra-ui/react';

export const BookingIcon = createIcon({
  displayName: 'BookingIcon',
  viewBox: '0 0 100 100',
  // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
  path: (
    <g>
      <path
        fill="currentColor"
        d="M58,22v9c0,1.1,0.9,2,2,2s2-0.9,2-2v-9c0-1.1-0.9-2-2-2S58,20.9,58,22z"
      />
      <path
        fill="currentColor"
        d="M60,80c2.5,1.9,5.6,3,9,3c8.3,0,15-6.7,15-15c0-5.7-3.2-10.7-8-13.3V27c0-1.1-0.9-2-2-2h-8c-1.1,0-2,0.9-2,2
		s0.9,2,2,2h6v10H20V29h6c1.1,0,2-0.9,2-2s-0.9-2-2-2h-8c-1.1,0-2,0.9-2,2v51c0,1.1,0.9,2,2,2H60C60,80,60,80,60,80z M20,76V43h52
		v10.3c-1-0.2-2-0.3-3-0.3c-8.3,0-15,6.7-15,15c0,2.9,0.9,5.7,2.3,8H20z M69,57c6.1,0,11,4.9,11,11s-4.9,11-11,11s-11-4.9-11-11
		S62.9,57,69,57z"
      />
      <path
        fill="currentColor"
        d="M38,25c-1.1,0-2,0.9-2,2s0.9,2,2,2h16c1.1,0,2-0.9,2-2s-0.9-2-2-2H38z"
      />
      <path
        fill="currentColor"
        d="M30,22v9c0,1.1,0.9,2,2,2c1.1,0,2-0.9,2-2v-9c0-1.1-0.9-2-2-2S30,20.9,30,22z"
      />
      <path
        fill="currentColor"
        d="M66.4,73.2c0.3,0.4,0.8,0.7,1.4,0.8c0.1,0,0.2,0,0.2,0c0.5,0,0.9-0.2,1.3-0.5l7-6c0.8-0.7,0.9-2,0.2-2.8
		c-0.7-0.8-2-0.9-2.8-0.2l-5.4,4.6l-1.7-2.3c-0.7-0.9-1.9-1.1-2.8-0.4c-0.9,0.7-1.1,1.9-0.4,2.8L66.4,73.2z"
      />
    </g>
  ),
});
