import { Fade, Box, chakra, GridItem, Grid, Container } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";

// Components
import { Header, Footer } from "ui";
import AccountMenu from "./layout/AccountMenu";

//- Context
import { useAuth } from "ui";

const Layout = (props) => {
  const { auth, authLoading } = useAuth();
  const { push } = useRouter();
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    if (!authLoading && !auth._id)
      push(`${process.env.NEXT_PUBLIC_WEBSITE_URL}/login`);
  }, [auth, authLoading, push]);

  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <Fade
      in={mounted}
      transition={{
        enter: { opacity: 0 },
        exit: {
          opacity: 1,
          transition: { duration: 1 },
        },
      }}
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <Header />
      <chakra.main flex={1}>
        <Container maxW="container.2xl" py={{ base: 8, md: 16, xl: 24 }}>
          <Grid
            templateColumns={{
              base: "1",
              lg: "repeat(4,1fr)",
              xl: "repeat(5,1fr)",
            }}
            gap={8}
          >
            <GridItem display={{ base: "none", md: "initial" }} colSpan={1}>
              <AccountMenu />
            </GridItem>
            <GridItem colSpan={{ base: 1, lg: 3, xl: 4 }}>
              <Box>{props.children}</Box>
            </GridItem>
          </Grid>
        </Container>
      </chakra.main>

      <Box marginBottom={{ base: 8, md: "unset" }}>
        <Footer />
      </Box>
    </Fade>
  );
};
export default Layout;
