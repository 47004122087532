import { Box } from "@chakra-ui/react";
import { motion, MotionValue } from "framer-motion";
import { ReactNode } from "react";

const routesAnimationVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: { duration: 0.4 },
  },
};

interface ITabPanelContent {
  children: any;
}

export const TabPanelContent = ({ children }: ITabPanelContent) => (
  <Box
    as={motion.div}
    variants={routesAnimationVariants}
    initial="hidden"
    animate="visible"
    pt={3}
  >
    {children}
  </Box>
);
